import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { ApiService } from "../../services";
import { IActiveProduct, IProduct } from "src/app/shared/models";
import { DatePipe,Location } from "@angular/common";

@Component({
  selector: "app-product-status",
  templateUrl: "./product-status.component.html",
  styleUrls: ["./product-status.component.less"],
  providers: [DatePipe],
})
export class ProductStatusComponent implements OnInit, OnChanges {
  @Input() productSku;
  @Input() product: IProduct;
  @Input() activeProduct: IActiveProduct;
  @Input() isDrop = "";
  @Input() showRelatedLabel = true;
  @Input() isHandset = false;
  @Input() page = "";
  @Input() zipCode = "";
  @Output() emitResponse = new EventEmitter();
  statusMessage = "Enter your zip code for delivery estimate";
  numberOfRetries = 3;
  currentTryNumber = 1;
  statusReceived = false;
  showZipCodeBox = true;
  fetchingInfo = false;
  shipZipAvailable: boolean;
  showIsDrop0Content = false;
  // @Output() showIsDrop0: EventEmitter<string> = new EventEmitter();

  constructor(private apiService: ApiService, private datePipe: DatePipe,
    private location:Location
  ) {}

  ngOnInit() {
    // this.statusMessage = this.page=='cart'?'Enter your zip code':'Enter your zip code for delivery estimate';
    const userZipcode = localStorage.getItem("userZipCode");
    if (userZipcode) {
      this.zipCode = userZipcode;
      this.getShippingInfo();
    }
  }

  get isPDP(){
    return this.location.path().includes('/product/')
  }

  ngOnChanges(changes: SimpleChanges): void {
    const userZipcode = localStorage.getItem("userZipCode");
    if (changes.productSku && !changes.productSku.firstChange) {
      this.getShippingInfo();
    }
    if (
      changes.zipCode &&
      changes.zipCode.currentValue &&
      !changes.zipCode.firstChange
    ) {
      this.zipCode = changes.zipCode.currentValue;
      if (this.zipCode !== userZipcode) {
        this.getShippingInfo();
      }
    }
  }

  getShippingInfo() {
    if (this.zipCode && this.productSku) {
      if (this.productSku.includes("-")) {
        this.productSku = this.productSku.split("-")[0];
      }
      this.fetchingInfo = true;
      localStorage.setItem("userZipCode", this.zipCode);
      const headers = new HttpHeaders({
        Accept: "application/json",
      });

      if (this.isDrop === "2") {
        const userZipcode = localStorage.getItem("userZipCode");
        // this.getProductShippingStatus();
        if (userZipcode) {
          this.apiService
            .getShipzipStatus(userZipcode, this.productSku)
            .subscribe((resp: any) => {
              if (resp.status == true) {
                this.showZipCodeBox = false;
                this.fetchingInfo = false;
                this.shipZipAvailable = true;
                this.showIsDrop0Content = true;
              } else {
                this.showZipCodeBox = false;
                this.fetchingInfo = false;
                this.shipZipAvailable = true;
                this.showIsDrop0Content = true;
              }
            });
        }
      } else {
        this.showIsDrop0Content = false;
        this.getProductShippingStatus();
      }
    }
  }
  getProductShippingStatus() {
    this.apiService
      .getProductShippingStatus(
        this.zipCode,
        this.productSku,
        this.product.site
      )
      .then(
        (response: any) => {
          if (response.error) {
            if (this.currentTryNumber <= this.numberOfRetries) {
              setTimeout(() => this.getShippingInfo(), 500);
              this.currentTryNumber++;
            } else {
              this.statusMessage =
                "Status unavailable. Please check back again later.";
              this.fetchingInfo = false;
            }
          } else {
            this.statusReceived = true;
            this.showZipCodeBox = false;
            this.fetchingInfo = false;

            // this.statusMessage = response.deliveryOptions[0].deliveryMessage;
            if (this.product.site === "westelm") {
              if (response.availabilityMessage.includes("In Stock")) {
                this.statusMessage =
                  "In Stock & Ready to Ship." +
                  " " +
                  response.deliveryOptions[0].deliveryMessage;
              } else if (response.availabilityMessage) {
                const minDelDate = this.datePipe.transform(
                  response.deliveryOptions[0].minimumDeliveryDate,
                  "MMM. dd"
                );
                const maxDelDate = this.datePipe.transform(
                  response.deliveryOptions[0].maximumDeliveryDate,
                  "MMM. dd"
                );
                // this.statusMessage = response.deliveryOptions[0].deliveryMessage;
                this.statusMessage = `Estimated delivery between ${minDelDate} and ${maxDelDate}`;
              } else {
                this.statusMessage = "Delivery Estimate Unavailable";
              }
            } else if (
              this.product.site === "cb2" ||
              this.product.site === "cab"
            ) {
              // if(!response.isNotAvailable){
              this.emitResponse.emit(response);
              // }
              if (response.isBackOrdered) {
                this.statusMessage =
                  response.backOrderedMessage +
                  " " +
                  response.backOrderedMessageDate +
                  " " +
                  "for zip code:";
              } else {
                this.statusMessage = response.onlineAvailableMessage;
              }
            }
            // else if (this.product.site.toUpperCase() === 'CAB') {

            // }
          }
        },
        (err) => {
          if (this.currentTryNumber <= this.numberOfRetries) {
            setTimeout(() => this.getShippingInfo(), 500);
            this.currentTryNumber++;
          } else {
            this.statusMessage =
              "Status unavailable. Please check back again later.";
            this.fetchingInfo = false;
          }
        }
      );
  }
}
