import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IProduct} from '../../../../shared/models';
import {UtilsService} from '../../../../shared/services';
import { smoothFade } from './animation';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product-features-new',
  templateUrl: './product-features-new.component.html',
  styleUrls: ['./product-features-new.component.less'],
  animations: [smoothFade]
})
export class ProductFeaturesNewComponent implements OnInit {

    @Input() product: IProduct;
    @Input() description: any;
    @Output() emitScroll = new EventEmitter();

    dimensionExist = false;
    featuresExist = false;
    assemblyExist = false;
    careExist = false;
    returnPolicyExist = false;
    descriptionExist = false;


    dimensionExpanded = false;
    featuresExpanded = false;
    assemblyExpanded = false;
    careExpanded = false;
    returnPolicyExpanded = false;
    descriptionExpanded = false

    isSingleDimension: boolean;

    activeFeatureTab = 'features';
    features: any;
    assembly: any;
    care: any;


    constructor(
        private utils: UtilsService,private location:Location
    ) {
    }

    ngOnInit() {
        this.isSingleDimension = this.product.dimension.length === 1;
        this.featuresExist = this.utils.checkDataLength(this.product.features);
        if (this.product.product_assembly != null) {
            this.assemblyExist = this.utils.checkDataLength(
                this.product.product_assembly
            );
        }
        if (this.product.product_care != null) {
            this.careExist = this.utils.checkDataLength(this.product.product_care);
        }
        this.returnPolicyExist = !!this.product.return_policy;
        this.dimensionExist = this.product.dimension.length > 0;
        this.assembly = this.utils.compileMarkdownText(this.product.product_assembly);
        this.care = this.utils.compileMarkdownText(this.product.product_care);
        this.features = this.utils.compileMarkdown(this.product.features);
        this.setFeatureTab();
        this.descriptionExpanded = true;
    }

    selectFeatureTab(tab,expanded) {
        this.activeFeatureTab = tab;
        // if(expanded){
            this.emitScroll.emit();
        // }
    }

    get isPreview(){
      return this.location.path().match(/preview/) !== null;
    }

    setFeatureTab(){
       if(this.featuresExist){
            this.activeFeatureTab = 'features';
        }else  if(this.dimensionExist){
            this.activeFeatureTab = 'dimensions';
        }else if(this.assemblyExist){
            this.activeFeatureTab = 'assembly';
        }else if(this.careExist){
            this.activeFeatureTab = 'care';
        }else if(this.returnPolicyExist){
            this.activeFeatureTab = 'returns';
        }
    }
}
