import { DatePipe, Location } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-design-proposals-summary',
  templateUrl: './design-proposals-summary.component.html',
  styleUrls: ['./design-proposals-summary.component.less'],
  providers:[DatePipe]
})
export class DesignProposalsSummaryComponent implements OnInit, OnChanges {
  @Input() projectImages: any[] = [];

  @Input() isHandset: boolean;
  showDetails = false;
  currentBoard: string;
  ideaVersionList: any[] = [];
  conceptVersionList: any[] = [];
  designVersionList: any[] = [];
  currentVersionList: any[] = [];
  outlineImg: string;

  ideaStatus: string='closed';
  conceptStatus: string='closed';
  designStatus:string='closed';


  constructor(private api: ApiService,private datePipe:DatePipe,private location:Location) { }
  

  ngOnInit(): void {
    this.outlineImg = 'https://s3.us-east-2.amazonaws.com/lazysuzy-images/site/design/design-project-outline.jpg';
    
  }
  back(e:any) {
    if(!e){
    history.pushState({ page: 'summary' }, '', '');  
    this.location.replaceState(`/admin/dashboard/design/rooms/${this.api.roomId}/designs`);
    } else { 
    history.pushState({ page: 'summary' }, '', ''); 
    this.location.replaceState(`/admin/dashboard/design/rooms/${this.api.roomId}/designs`);
    }
    this.getDesignImage();
    this.showDetails = false; 
  }

  getDesignImage() {
    this.api.getDesignProject(this.api.roomId).subscribe((resp: any) => {
      if (resp && resp.length > 0) {
        this.projectImages = resp;
        this.takeImages();
      }
    });
  }
  setBoard(b: string) {
    this.currentBoard = b;
    if (b === 'Idea Boards') {
      this.currentVersionList = this.ideaVersionList;
    } else if (b === 'Concept Boards') {
      this.currentVersionList = this.conceptVersionList;
    } else {
      this.currentVersionList = this.designVersionList;
    }
    history.pushState({ page: 'details' }, '', '');
    this.showDetails = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.projectImages && changes.projectImages.currentValue) {
      this.projectImages = changes.projectImages.currentValue;
      this.takeImages();
    }
  }
  takeImages() {

    if (this.projectImages.length > 0) {
      this.ideaVersionList = [];
      this.conceptVersionList = [];
      this.designVersionList = [];

      const ideaVersions = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'idea')[0]?.versions;
      const conceptVersions = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'concept')[0]?.versions;
      const designVersions = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'design')[0]?.versions;


      for (var v in ideaVersions) {
        ideaVersions[v]?.images?.forEach(f => f.imgUrl = f.link)
        this.ideaVersionList.push({
          versionNo:this.datePipe.transform(new Date(ideaVersions[v].version_date),'MMM-dd') , checked: false, ...ideaVersions[v],
          imagesList: ideaVersions[v].images
        })
      }

      for (var v in conceptVersions) {
        conceptVersions[v]?.images?.forEach(f => f.imgUrl = f.link)
        this.conceptVersionList.push({ 
          versionNo:this.datePipe.transform(new Date(conceptVersions[v].version_date),'MMM-dd'), checked: false, ...conceptVersions[v], imagesList: conceptVersions[v].images })
      }
      for (var v in designVersions) {
        designVersions[v]?.images?.forEach(f => f.imgUrl = f.link)
        this.designVersionList.push({ 
        versionNo:this.datePipe.transform(new Date(designVersions[v].version_date),'MMM-dd') , checked: false, ...designVersions[v], imagesList: designVersions[v].images })
      }

      const isConceptPublished = this.conceptVersionList.some(s=>s.is_published==0) || this.conceptVersionList.length===0;
      const isDesignPublished = this.designVersionList.some(s=>s.is_published==0) || this.designVersionList.length===0;

      this.ideaStatus = this.ideaVersionList.some(s=>s.is_published==1) ? 'published' : 'open';

      this.conceptStatus =  this.conceptVersionList.some(s=>s.is_published==1) ? 'published':
      (this.ideaVersionList.some(s=>s.is_published==1) && 
      isConceptPublished) ? 'open'  :'closed';


      this.designStatus =  this.designVersionList.some(s=>s.is_published==1) ? 'published':
      (this.conceptVersionList.some(s=>s.is_published==1) && isDesignPublished) ? 'open'  :'closed'


    }
  }

}
