import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IActiveProduct } from 'src/app/shared/models';
import { UtilsService } from 'src/app/shared/services';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-product-status-isdrop',
  templateUrl: './product-status-isdrop.component.html',
  styleUrls: ['./product-status-isdrop.component.less']
})
export class ProductStatusIsdropComponent implements OnInit {
  @Input() activeProduct: IActiveProduct;
  @Input() product: any;
  @Input() page='';
  @Input() zipcode='';
  @Input() showSku: boolean;
  @Input() productSku: any;
  @Input() showRelatedLabel: boolean = true;
  @Output() emitResponse = new EventEmitter();
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset: boolean;
  constructor(public utils: UtilsService,
    private breakpointObserver: BreakpointObserver,) { }

  ngOnInit() {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
          this.isHandset = handset.matches;
      }
  );
  
  }
  getResponse(e){
    this.emitResponse.emit(e);
  }

}
