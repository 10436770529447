import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddToOrderComponent } from 'src/app/modules/order-list/add-to-order/add-to-order.component';
import { ApiService } from 'src/app/shared/services';
import { AddShoppingProductDialogComponent } from '../add-shopping-product-dialog/add-shopping-product-dialog.component';
import { Router } from '@angular/router';
import { P } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.less']
})
export class ShoppingListComponent implements OnInit {
  @Input() isHandset: boolean;
  productListings: any = null;
  isFetched: boolean;
  qtyList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  total = 0;
  spinner = "assets/image/spinner.gif";
  public Math: Math = Math;
  @ViewChild("confirmDialog") confirmDialog: TemplateRef<any>;
  prodToRemove: any;
  masterToggle = false;
  currentSubNav = 1;
  include: boolean = false;
  originalList: any[] = [];
  rows = new Array(10);

  constructor(private api: ApiService, private snackbar: MatSnackBar,
    public dialog: MatDialog, private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.api.roomId)
    this.getShoppingList();
  }
  setSubNav(no) {
    this.total = 0;
    this.productListings = null;
    if (this.currentSubNav === 4 && no !== 4) {
      this.getShoppingList();
      this.currentSubNav = no;

    } else {
      this.currentSubNav = no;
      if (this.currentSubNav === 2) this.productListings = this.originalList.filter(f => f.is_approved === 0 && f.is_shared === 1);
      if (this.currentSubNav === 3) this.productListings = this.originalList.filter(f => f.is_approved === 1 && f.is_shared === 1);

      if (this.currentSubNav === 1) this.productListings = JSON.parse(JSON.stringify(this.originalList));
    }
    this.checkAlternate({ target: { checked: this.include } });

    if (this.currentSubNav === 4) this.callCartServiceRep();


  }
  callCartServiceRep() {
    this.isFetched = false;
    // this.api.roomId = '170874'
    this.productListings = null;
    this.api.getCartProductsFromDashboard(this.api.roomId).subscribe((res: any) => {

      if (res.products && res.products.length > 0) {
        this.productListings = res.products;
        // this.productListings[0].is_alternate = 1;
        this.productListings.forEach(f => { 
          f['min_price'] = f.price; 
          f['qty'] = JSON.parse(JSON.stringify(f.count));
          f['total_price'] = parseFloat(f.count) * parseFloat(f.min_price);
        });
        this.checkAlternate({ target: { checked: true } });
        this.originalList = JSON.parse(JSON.stringify(this.productListings));
        // this.setSubNav(this.currentSubNav?this.currentSubNav:1);
        this.isFetched = true;
      } else {
        this.isFetched = true;
        this.productListings = [];

      }
    })
  }

  // getShoppingList() {
  //   this.isFetched = false;
  //   // this.api.roomId = '170874'
  //   this.productListings = null;
  //   this.api.getAdminShoppingList(this.api.roomId).subscribe((res: any) => {

  //     if (res && res.products.length > 0) { 
  //       this.productListings = res.products; 
  //       this.productListings.forEach(f => {
  //         f['checked'] = false;
  //         f['qty'] = JSON.parse(JSON.stringify(f.count));
  //         f['min_price'] = f.price;
  //         f['total_price'] = parseFloat(f.count) * parseFloat(f.min_price);
  //       });
  //       // this.checkAlternate({target:{checked:this.include}});
  //       this.originalList = JSON.parse(JSON.stringify(this.productListings));
  //       this.setSubNav(this.currentSubNav ? this.currentSubNav : 1);
  //       this.isFetched = true;

        
  //     } else {
  //       this.isFetched = true;
  //       this.productListings = [];
  //     }
  //   })
  // }


  
  getShoppingList(page: number = 0, allProducts: Map<number, any> = new Map()) {
    this.isFetched = false;
  
    this.api.getAdminShoppingList(this.api.roomId, page).subscribe(
      (res: any) => {
        if (res && res.products.length > 0) {
          res.products.forEach((product: any) => {
            if (!allProducts.has(product.product_sku)) {
              allProducts.set(product.product_sku, {
                ...product,
                checked: false,
                qty: JSON.parse(JSON.stringify(product.count)),
                min_price: product.price,
                total_price: parseFloat(product.count) * parseFloat(product.price),
              });
            }
          });
  
          // Immediately update the UI with fetched data
          this.productListings = Array.from(allProducts.values());
          this.originalList = JSON.parse(JSON.stringify(this.productListings));
          this.setSubNav(this.currentSubNav ? this.currentSubNav : 1);
  
          // Check if more pages exist
          const totalPages =( Math.ceil(res.total / 20))-1;

          if (page < totalPages) {
            this.getShoppingList(page + 1, allProducts);
          } else {
            this.isFetched = true;
          }
        } else {
          this.isFetched = true;
        }
      },
      (error) => {
        console.error('Error fetching shopping list:', error);
        this.isFetched = true;
      }
    );
  }
  
  

  toggleAlternate(event, product) {
    const data = {
      "room_id": this.api.roomId,
      "product_sku": product.product_sku
    }


    this.api.designProductAlternate(data).subscribe((res: any) => {
      this.checkAlternate({ target: { checked: this.include } })
      if (res.status) {
        this.getShoppingList();
        this.snackbar.open('Product alternate has been done', 'x', { duration: 3000 })
      } else {
        this.snackbar.open('Failed: API Error', 'x', { duration: 3000 })

      }
    }, err => {
      this.snackbar.open('Failed: API Error', 'x', { duration: 3000 })

    })

  }


  share(shared: number) {
    const data = {
      "room_id": this.api.roomId,
      "product_sku": this.productListings.filter(f => f.checked).map(m => m.product_sku).join(','),
      is_shared: shared
    }


    this.api.designProductShare(data).subscribe((res: any) => {
      if (res.status) {
        this.getShoppingList();
        this.snackbar.open('Product shared with client successfully', 'x', { duration: 3000 })
      } else {
        this.snackbar.open('Failed: API Error', 'x', { duration: 3000 })

      }
    }, err => {
      this.snackbar.open('Failed: API Error', 'x', { duration: 3000 })

    })
  }


  addCart() {
    const data = {
      "room_id": this.api.roomId,
      "product_sku": this.productListings.filter(f => f.checked).map(m => m.product_sku).join(','),
      "count": this.productListings.filter(f => f.checked).map(m => m.qty).join(','),
    }



    this.api.addCartProduct(data).subscribe((res: any) => {
      if (res.status) {
        this.getShoppingList();

        this.snackbar.open('Product added to cart successfully', 'x', { duration: 3000 });
      } else {
        this.snackbar.open('Failed: API Error', 'x', { duration: 3000 })
      }
    }, err => {
      this.snackbar.open('Failed: API Error', 'x', { duration: 3000 })

    })
  }
  openConfirmPopup(product = null) {
    if (product) {
      this.prodToRemove = product;
      this.dialog.open(this.confirmDialog, { width: '300' })
    } else {
      if (this.selections) {
        this.dialog.open(this.confirmDialog, { width: '300' })
      } else {
        this.snackbar.open('Select atleast one product to remove', 'x', { duration: 3000 })
      }
    }
  }

  remove() {
    let productsToRemove = [];
    if(this,this.currentSubNav===4){
      productsToRemove = [this.prodToRemove]
    } else{
    this.productListings.forEach(f => {
      if (f.checked)
        productsToRemove.push({ product_sku: f.product_sku, count: f.qty })
    });
  }

    this.decreaseQuantity(productsToRemove, true)
  }

  create() {
    this.router.navigate([]).then(() => {
      window.open(`/admin/dashboard/orders/new-order?room_id=${this.api.roomId}`);
    });
  }

  toggleMaster(event) {
    if (event.target.checked) {
      this.productListings.forEach(f => f.checked = true);
    } else {
      this.productListings.forEach(f => f.checked = false);
    }
  }
  checkToggle() {

    if (this.productListings?.every(f => f.checked)) {
      this.masterToggle = true
    } else {
      this.masterToggle = false;
    }
  }
  checkAlternate(event) {
    if (this.productListings) {
      if (event.target.checked) {
        this.total = this.productListings.reduce((a, b) => {
          return a + b.total_price;
        }, 0)
      } else {
        this.total = this.productListings.filter(f => f.is_alternate === 0 || f.is_alternate == false).reduce((a, b) => {
          return a + b.total_price;
        }, 0)
      }
    }
  }
  get selections(): boolean {
    return this.productListings?.some(f => f.checked);
  }

  openAddToOrder() {
    this.dialog
      .open(AddShoppingProductDialogComponent, {
        // width: "700px",
        data: this.api.roomId,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getShoppingList();
        }
      });
  }

  onQuantityChanged(product, count, quantity) {
    if ((+quantity) >= count) {
      const updateQuantity = (+quantity) - count;
      this.increaseQuantity(product, updateQuantity);
    } else {
      const updateQuantity = count - (+quantity);

      let productsToRemove = [{ product_sku: product.product_sku, count: updateQuantity }];
      this.decreaseQuantity(productsToRemove, false);
    }
    setTimeout(() => {

      product.total_price = parseFloat(quantity) * parseFloat(product.min_price);
    }, 500);
  }


  increaseQuantity(product, quantity) {
    // const postData = {
    //   product_sku: product.product_sku,
    //   count: +quantity,
    //   parent_sku: product.parent_sku,
    //   room_id: this.api.roomId
    // };

    let data: any;
    if (this.currentSubNav === 4) { 
      data = {
        product_sku: product.product_sku,
        count: +quantity,
        parent_sku: product.parent_sku,   
        isServiceRepOrder: 1,
        is_virtual: 0,
        room_id: this.api.roomId
      };
    } else {
      data = {
      product_sku: product.product_sku,
      count: +quantity,
      parent_sku: product.parent_sku,
      room_id: this.api.roomId
      };
    }

    let decide = this.currentSubNav === 4 ? this.api.addCartProduct(data) : this.api.addDesignCartProduct(data)

    decide.subscribe(
      (payload: any) => {
        if (payload && payload.status) {
          this.currentSubNav === 4 ? this.callCartServiceRep() : this.getShoppingList();
          this.snackbar.open('Quantity updated', 'x', { duration: 3000 });
        } else {
          this.snackbar.open('API Error', 'x', { duration: 3000 });

        }

      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  decreaseQuantity(productsToRemove, isCompleteRemove) {

    let data: any;
    if (this.currentSubNav === 4) {
      data = {
        
       "products":[
        {"product_sku":productsToRemove[0].product_sku,"count":productsToRemove[0].count},
     ], 
        isServiceRepOrder: 1,
        is_virtual: 0,
        room_id: this.api.roomId
      };
    } else {
      data = {
        "product_sku": productsToRemove.map(m => m.product_sku).join(','),
        "count": productsToRemove.map(m => m.count).join(','),
        room_id: this.api.roomId
      };
    }
    let decide = this.currentSubNav === 4 ? this.api.removeCartProduct(data) : this.api.removeDesignCartProduct(data)

    decide.subscribe(
      (payload: any) => {
        if (payload && payload.status) {
          this.currentSubNav === 4 ? this.callCartServiceRep() : this.getShoppingList();
          if (isCompleteRemove) {
            this.snackbar.open('Produce removed successfully', 'x', { duration: 3000 });
            this.dialog.closeAll();

          } else {
            this.snackbar.open('Quantity updated', 'x', { duration: 3000 });

          }
        } else {
          this.snackbar.open('API Error', 'x', { duration: 3000 });

        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  openProductInNewTab(p: any) {
    this.router.navigate([]).then(() => {
      const productUrl = p.parent_sku ? p.parent_sku : p.product_sku;
      window.open(`product/${productUrl}`, "_blank");
    });
  }

  get pageWidth() {
    return document.body.clientWidth;
  }
}
