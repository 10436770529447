import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
} from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-projects-landing",
  templateUrl: "./projects-landing.component.html",
  styleUrls: ["./projects-landing.component.less"],
})
export class ProjectsLandingComponent implements OnInit, OnDestroy {
  projectList: any[] = [];
  userName: string;
  designStatus: any[] = [];
  roomCodes: any[] = [];
  eventSubscription: Subscription;
  hasProjects = "";
  outlineImg: string;

  constructor(
    private api: ApiService,
    public envService: EnvService,
    private eventEmitterService: EventEmitterService,
    private matDialogUtil: MatDialogUtilsService
  ) {
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        //   this.customerData.email = user.email;
        // if(user.email){ 
        this.initCalls();
        // }
      });
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }
  initCalls() {
    if (this.isLoggedIn) {
      this.api.getDesignStausList().subscribe((res: any) => {
        console.log(res);
        this.designStatus = res;
        this.api.getRoomCodes().subscribe((res: any) => {
          this.roomCodes = res;
          this.get();
        });
      });
    } else {
      this.matDialogUtil.openSignupDialog(
        window.innerWidth >= 600 ? false : true
      );
    }
  }
  ngOnInit(): void {
    this.outlineImg = 'https://s3.us-east-2.amazonaws.com/lazysuzy-images/site/design/design-project-outline.jpg'
  }
  titleCase(input): string {
    return input
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  get() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.userName = user.first_name // user.name;+ ' ' + user.last_name;
    // this.userName = t
    this.api.getAllAppointments("", "", user.id).subscribe((res) => {
      if (res && res.length > 0) { 
        this.projectList =  res.filter(f => f.status === '01'||
          f.status === '02' ||f.status === '03'|| f.status === '04'||
          f.status === '09'|| f.status === '70'||f.status === '90'
        );
        if (this.projectList.length > 0) { this.hasProjects = "YES" } else {
          this.hasProjects = "NO";
        }

        this.projectList.forEach((f) => {

          let path = this.designStatus.find(
            (fs) => fs.code === f.status
          )?.redirect_path;
          f["btnAction"] = this.designStatus.find(
            (fs) => fs.code === f.status
          ).label_action;
          const labelData = this.roomCodes.find((fs) => fs.code === f.room)
          f["label"] = labelData ? labelData.label : this.titleCase(f.room);
          f["path"] = path ? path.replace('%', f.room_id) : ''; 

          const designData = this.findValidProposalImage(f, 'design');
          const conceptData = this.findValidProposalImage(f, 'concept');
          const ideaData = this.findValidProposalImage(f, 'idea');


          if (f.status === '01' || f.status === '02' || f.status === '90') {
            f["image"] = this.findValidIntakeImage(f, 'inspo');
          } else if ((f.status === '03'||f.status === '04'||f.status === '70'||f.status === '90')) {
            if (designData && designData.link) {
              f["image"] = designData.link;
            } else if (conceptData && conceptData.link) {
              f["image"] = conceptData.link;
            } else if (ideaData && ideaData.link) {
              f["image"] = ideaData.link;
            } else {
              f["image"] =  this.findValidIntakeImage(f, 'room');
            }
          } else if ((f.status === '09')) { 
            if (designData && designData.link) {
              f["image"] = designData.link;
            } else {
              f["image"] = this.outlineImg;
            }
          } else {
            f["image"] = this.outlineImg
          }
        });
      } else {
        this.hasProjects = "NO";

      }
    }, err => {
      this.hasProjects = "NO";
    });
  }

  findValidIntakeImage(data: any, type: string): any | null {
    let index = data.intakeimage.findIndex(f => f.type === type);
    return data.intakeimage.length > 0 && index > -1 && data.intakeimage[0].images.length > 0 &&
      data.intakeimage[0].images[0].image ?
      data.intakeimage[0].images[0].image :
      this.outlineImg
  }

  findValidProposalImage(data: any, type: string): any | null {
    for (const deliverable of data.design_deliverables) {
      if (deliverable.type === type) {
        for (const version of Object.values(deliverable.versions)) {
          if (version['is_published'] === 1 && version['images']) {
            for (const image of version['images']) {
              if (image.is_archived === 0) {
                return image;
              }
            }
          }
        }
      }
    }
    return null;
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }
}
