import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductsCarousalComponent} from './components/products-carousal/products-carousal.component';
import {OwlModule} from 'ngx-owl-carousel';
import {LoaderModule} from './components/loader/loader.module';
import {MaterialModule} from './material-module';
import {AmericanNumberPipe} from './pipes/american-number.pipe';
import {RouterModule} from '@angular/router';
import {WishlistSnackbarComponent} from './components/wishlist-snackbar/wishlist-snackbar.component';
import {AmericanCurrencyCustomFormatPipe} from './pipes/american-currency-custom-format.pipe';
import {CancelConfirmationDialogComponent} from '../modules/seller/dialogs/cancel-confirmation-dialog/cancel-confirmation-dialog.component';
import {RangeFilterComponent} from './components/range-filter/range-filter.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; 
import { ProductStatusIsdropComponent } from './components/product-status-isdrop/product-status-isdrop.component';
import { ProductStatusComponent } from './components/product-status/product-status.component';
import { SortTypeComponent } from '../feature/components/sort-type/sort-type.component';
import { PstTimePipe } from './pipes/pst-time.pipe';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { ExpertDesignerSectionModule } from '../core/components/expert-designer-section/expert-designer-section.module';
import { NoProductFoundComponent } from './components/no-product-found/no-product-found.component';
import { AuthModule } from '../core/components/auth/auth.module';
import { BrowseByRoomComponent } from '../feature/components/browse-by-room/browse-by-room.component';
import { SecondaryBannerComponent } from '../feature/containers/landing/secondary-banner/secondary-banner.component';
import { ShopFaqHelpComponent } from '../feature/containers/landing/shop-faq-help/shop-faq-help.component';
import { PaypalComponent } from './components/paypal/paypal.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { StripePaymentComponent } from './components/stripe-payment/stripe-payment.component';
import { ProductStatusMobileComponent } from '../feature/components/product-details-mobile/product-status-mobile/product-status-mobile.component';

const DECLARATIONS = [ProductsCarousalComponent, AmericanNumberPipe, RangeFilterComponent];

const MODULES = [
    CommonModule,
    OwlModule,
    LoaderModule,
    MaterialModule,
    RouterModule,
    ExpertDesignerSectionModule,
     
];

@NgModule({
    declarations: [
        ...DECLARATIONS,
        WishlistSnackbarComponent,
        AmericanCurrencyCustomFormatPipe,
        CancelConfirmationDialogComponent,
        ProductStatusComponent,
        ProductStatusMobileComponent,
        ProductStatusIsdropComponent,
        SortTypeComponent,
        PstTimePipe,
        SubscribeComponent,
        NoProductFoundComponent,
        BrowseByRoomComponent,
        ShopFaqHelpComponent,
        SecondaryBannerComponent,
        PaypalComponent,
        SidePanelComponent,
        StripePaymentComponent
    ],
    imports: [...MODULES, NgxSliderModule, FormsModule,AuthModule,ReactiveFormsModule],
    exports: [...DECLARATIONS, ...MODULES,   ProductStatusComponent,SortTypeComponent,SubscribeComponent,
        ProductStatusMobileComponent,
        ProductStatusIsdropComponent, AmericanCurrencyCustomFormatPipe,PstTimePipe,BrowseByRoomComponent,
        ShopFaqHelpComponent,SecondaryBannerComponent,PaypalComponent,NgxSliderModule,SidePanelComponent,StripePaymentComponent],
    entryComponents: [
        WishlistSnackbarComponent,
        CancelConfirmationDialogComponent
    ],
})
export class SharedModule {
}
