import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "app-paypal",
  templateUrl: "./paypal.component.html",
  styleUrls: ["./paypal.component.less"],
})
export class PaypalComponent implements OnInit,AfterViewInit {
  showSuccess: boolean;
  @Output() executePayment = new EventEmitter();
  @Output() executeFailedPayment = new EventEmitter();
  @ViewChild("card-expiry-field-container", { static: true })
  expiry: ElementRef;

  amount = 0;
  @Input() totalAmount = 0;
  @Input() buttonName = "PLACE ORDER";
  @Input() btnClass = "place-order-btn";
  @Input() screen = "OrderCheckout";
  @Input() dataForValidation: any;
  @Input() roomId: any;

  orderId: string;
  accessToken: any;
  paymentError: string;
  isPaymentExecuting: boolean;
  token: any; 
  orderData: any;
  finalData: any;
  isHandset: boolean;

  constructor(private router: Router, private api: ApiService,
    private breakpointObserver: BreakpointObserver,

  ) {

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
    
      });
  }

  ngOnInit(): void { 
    this.amount = this.totalAmount;

  }
 ngAfterViewInit(): void {
  if(this.screen==='BookingQuiz'){
    this.initializeBookingCardFields();
  } else {

    this.initializeCardFields();
  }
 }

  initializeCardFields() {
    let styleObject = {
      input: {
        "font-size": "16px",
      },
    };
    const cardFields = paypal.CardFields({
      style: styleObject,
      createOrder:()=>{
        if(this.validateCheckoutForm()){
          if(this.screen==='Booking' || this.screen === 'BookingQuiz'){
          const  obj = {
              payment_method:'paypal',
              room_id:this.roomId
            }
            return this.api.customerCreate(obj).toPromise().then((res: any) => { 
              // this.paymentCharge(res);
              this.orderData = res;
              return res.payment_id;
            });
          } else {
            const obj = {
              shipping_address_id: this.finalData.shippingId,
              billing_address_id: this.finalData.billingId,
              email: this.finalData.email,
              name: this.finalData.name,
              payment_method: this.finalData.payment_method
            };
            return this.api.createOrder(obj).toPromise().then((res: any) => { 
              // this.paymentCharge(res);
              this.orderData = res;
              return res.payment_id;
            });
          }
    
      } else {
        return null;
      }
      }, 
      onApprove: () => {
        this.paymentCharge(this.orderData);
      }, 
      onError: (error) => {
        this.isPaymentExecuting = false; 
        console.error(error);
      },
    });

    const cardNameContainer = document.getElementById(
      "card-name-field-container"
    );
    const nameField = cardFields.NameField({
  
      inputEvents: {
        onChange: (event) => {
           },
      },
    
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.75rem 0.75rem !important',
          'font-size':'16px !important'
      },
    }
    });

    nameField.render(cardNameContainer);
    const cardNumberContainer = document.getElementById(
      "card-number-field-container"
    );
    const numberField = cardFields.NumberField({
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.75rem 0.75rem !important'
      },
    }
    });
    numberField.render(cardNumberContainer);
    const cardExpiryContainer = document.getElementById(
      "card-expiry-field-container"
    );
    const expiryField = cardFields.ExpiryField({
      placeholder:"MM/YY",
    
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.75rem 0.75rem !important'
      },
    }
    });
    expiryField.render(cardExpiryContainer);
    const cardCvvContainer = document.getElementById(
      "card-cvv-field-container"
    );
    const cvvField = cardFields.CVVField({
     
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.75rem 0.75rem !important'
      },
    }
    });

    cvvField.render(cardCvvContainer);

    const multiCardFieldButton = document.getElementById(
      "multi-card-field-button"
    );
    multiCardFieldButton.addEventListener("click", () => {
      if (this.screen === "OrderCheckout") {
        if (this.validateCheckoutForm()) {
          this.payWithPaypal(cardFields);
        } else {
          this.paymentError = " Please fill all the required fields.";
        }
      } else {
        this.payWithPaypal(cardFields);
      }
    });
  } 

  payWithPaypal(cardFields) {
    cardFields.getState().then((data) => { 
      if (data.isFormValid) { 

          this.isPaymentExecuting = true;
          this.paymentError = "";
          cardFields
            .submit()
            .then((res) => {
              console.log(res, "RES"); 
            })
            .catch((err) => {
              this.isPaymentExecuting = false;
              this.paymentError =
                " Please try again or use a different payment method."; 
            });
        // }
      } else {
        this.isPaymentExecuting = false;
        this.paymentError = " Please fill all the required fields.";
      }
    });
  }
  async createOrder() {
    const obj = {
      shipping_address_id: this.finalData.shippingId,
      billing_address_id: this.finalData.billingId,
      email: this.finalData.email,
      name: this.finalData.name,
      payment_method: this.finalData.payment_method
    };
     this.api.createOrder(obj).subscribe((res: any) => {
      console.log(res, "CREATE ORDER RES");
      this.paymentCharge(res);
    });
  }
  async paymentCharge(data) {

    this.orderId = data.payment_id; 
    let obj ={
      payment_id:data.payment_id,
      order_id:data.order_id,
    }
    if(this.screen==='Booking' || this.screen==='BookingQuiz'){
      this.api.paymentChargeBooking(obj).subscribe((res: any) => { 
 
        setTimeout(() => {
          this.paymentError = ""; 
        this.isPaymentExecuting = false;
        }, 1000);
        this.executePayment.emit(res);
      },err=>{
        this.paymentError =" Please try again or use a different payment method.";
        this.isPaymentExecuting = false;
      });
    } else {
      this.api.paymentCharge(obj).subscribe((res: any) => { 
        setTimeout(() => {
          this.paymentError = ""; 
        this.isPaymentExecuting = false;
        }, 1000);
        this.executePayment.emit(res);
      },err=>{
        this.paymentError =" Please try again or use a different payment method.";
        this.isPaymentExecuting = false;
      });
    }

  }
  validateCheckoutForm() {
   
    if(this.screen=='Booking' || this.screen=='BookingQuiz'){
      return true;
    //   let condition =false;
    //   if(this.dataForValidation &&this.dataForValidation.valid){
    //     this.finalData = this.dataForValidation.value;
    //     const data = this.dataForValidation.value;
    //      condition =
    //     data.email &&
    //     data.firstName && 
    //     data.phone &&
    //     data.zipcode;
    //   } else {
    //     let user =JSON.parse( localStorage.getItem('user'));
    //     if(user){
    //      this.finalData = {
    //       email: user.email,
    //       firstName: user.first_name,
    //       lastName:user.last_name, 
    //      }
    //      condition =
    //      this.finalData.email &&
    //      this.finalData.firstName 
         
    //     } 
    //   }
  
    // if (condition) {
    //   return true;
    // } else {
    //   return false;
    // }

    } else {  
      this.finalData = this.dataForValidation;
      const data = this.dataForValidation;
          const condition =
      data.email &&
      data.shipping_f_Name &&
      data.shipping_l_Name &&
      data.shipping_address_line1 &&
      data.shipping_state &&
      data.shipping_zipcode &&
      data.shipping_phone &&
      data.shipping_country &&
      data.shipping_city &&
      data.billing_f_Name &&
      data.billing_l_Name &&
      data.billing_address_line1 &&
      data.billing_state &&
      data.billing_zipcode &&
      data.billing_phone &&
      data.billing_country &&
      data.billing_city;
    if (condition) {
      return true;
    } else {
      return false;
    }
    }

  }
  cancel() {
    this.router.navigate(["checkout"]);
  }

  initializeBookingCardFields() {
    let styleObject = {
      input: {
        "font-size": "16 px",
      },
    };
    const cardFields = paypal.CardFields({
      style: styleObject,
      createOrder:()=>{
        if(this.validateCheckoutForm()){
          if(this.screen==='Booking' || this.screen === 'BookingQuiz'){
            const  obj = {
              payment_method:'paypal',
              room_id:this.roomId
            }
            return this.api.customerCreate(obj).toPromise().then((res: any) => { 
              // this.paymentCharge(res);
              this.orderData = res;
              return res.payment_id;
            });
          } else {
            const obj = {
              shipping_address_id: this.finalData.shippingId,
              billing_address_id: this.finalData.billingId,
              email: this.finalData.email,
              name: this.finalData.name,
              payment_method: this.finalData.payment_method
            };
            return this.api.createOrder(obj).toPromise().then((res: any) => { 
              // this.paymentCharge(res);
              // if(res && res.status!=='failed'){
              this.orderData = res;
              return res.payment_id;
              // } else {

              // }
            },err=>{
              console.log(err,'error001')
            });
          }
    
      } else {
        return null;
      }
      }, 
      onApprove: () => {
        this.paymentCharge(this.orderData);
      }, 
      onError: (error) => {
        this.isPaymentExecuting = false; 
        console.error(error);
      },
    });

    const cardNameContainer = document.getElementById(
      "card-name-field-container"
    );
    const nameField = cardFields.NameField({
      placeholder:this.isHandset? "Name On Card": "",

      inputEvents: {
        onChange: (event) => {
           },
      },
    
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.5rem 0.75rem !important',
          'font-family':"'FS Meridian'",
          'font-size':'18px' 
      },
    }
    });

    nameField.render(cardNameContainer);
    const cardNumberContainer = document.getElementById(
      "card-number-field-container"
    );
    const numberField = cardFields.NumberField({
      placeholder:this.isHandset? "Credit Card Number": "",
   
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.5rem 0.75rem !important',
          'font-family':"'FS Meridian'",
          'font-size':'18px' 
      },
    }
    });
    numberField.render(cardNumberContainer);
    const cardExpiryContainer = document.getElementById(
      "card-expiry-field-container"
    );
    const expiryField = cardFields.ExpiryField({
      placeholder:"MM/YY",
    
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.5rem 0.75rem !important',
          'font-family':"'FS Meridian'",
          'font-size':'18px' 
      },
    }
    });
    expiryField.render(cardExpiryContainer);
    const cardCvvContainer = document.getElementById(
      "card-cvv-field-container"
    );
    const cvvField = cardFields.CVVField({
      placeholder:"",
   
      style: {
        ":focus": {
          'box-shadow': 'none',
        },
        ":focus .invalid": {
          'box-shadow': 'none',
        },
        'input': {
          'padding': '0.5rem 0.75rem !important',
          'font-family':"'FS Meridian'",
          'font-size':'18px' 
      },
    }
    });

    cvvField.render(cardCvvContainer);

    const multiCardFieldButton = document.getElementById(
      "multi-card-field-button"
    );
    multiCardFieldButton.addEventListener("click", () => {
      if (this.screen === "OrderCheckout") {
        if (this.validateCheckoutForm()) {
          this.payWithPaypal(cardFields);
        } else {
          this.paymentError = " Please fill all the required fields.";
        }
      } else {
        this.payWithPaypal(cardFields);
      }
    });
  } 
}
