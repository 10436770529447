import {AfterViewInit, Component, DoCheck, HostListener, Input, OnInit} from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils/utils.service'; 
import { EnvService } from '../../../shared/services/env.service';

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.less']
})
export class AboutusComponent implements OnInit,AfterViewInit {
    logoPath = '';
    isHandset = false; 
    assetBaseUrl = '';
    bgImg= '';
  outerHeight: number=800;
    constructor(private utils: UtilsService,public env:EnvService) {
      this.logoPath = this.env.logoPathWhite;
      this.assetBaseUrl = this.env.assetBaseUrl;
      if(window.innerWidth>400){
      this.bgImg = this.env.assetBaseUrl+'site/about/about_cover.jpg';
      }else{
        this.bgImg = this.env.assetBaseUrl+'site/about/about_cover_mobile.jpg';
      }

    }

    @HostListener('window:resize', ['$event'])
    resize(event){
      this.outerHeight = event.target.outerHeight;
    }
 

    ngOnInit() { 
      // console.log(outerHeight,'Outerh')
      this.outerHeight = window.outerHeight;
        this.utils.isHandset().subscribe(handset => {
            this.isHandset = handset.matches;
        
        });
      }
      ngAfterViewInit() {
        console.log('this.isHandset', document.getElementsByClassName('our-features'));
        document.getElementById("about-bg").style.backgroundImage = `url(${this.bgImg})`;
      }
  
    get isLoggedIn(): boolean {
      if (JSON.parse(localStorage.getItem("user"))) {
        return (
          JSON.parse(localStorage.getItem("user")) &&
          JSON.parse(localStorage.getItem("user")).email &&
          JSON.parse(localStorage.getItem("user")).email.length > 0
        );
      } else {
        return false;
      }
    }
  
    showInterCom() {
        (<any>window).Intercom(
          "showNewMessage",
          "Hi there! I see you would like to chat with us."
        );
      }
}
