import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService, UtilsService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-stripe-payment",
  templateUrl: "./stripe-payment.component.html",
  styleUrls: ["./stripe-payment.component.less"],
})
export class StripePaymentComponent implements OnInit, OnDestroy {
  isHandset = false;
  currentPage = 1;
  desPayResp: any;
  clientSecret: any;
  stripe: any;
  elements: any;
  deviceHeight: number;
  deviceWidth: number;
  errorMsg: string;
  amount: number;
  @Input() totalAmount: number;
  @Input() roomId: string;
  @Output() executePayment = new EventEmitter();
  @Output() amountEmit = new EventEmitter();

  @Output() executeFailedPayment = new EventEmitter();
  @Output() executeFailedPromo = new EventEmitter();

  paymentInProgress: boolean;
  paymentError: string;
  promo: string;
  paymentId: any;
  promoError: string = '';
  showPhone: boolean;
  phone = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/) // (123) 456-7890 format
  ])
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private utils: UtilsService,
    private router: Router,
    public env: EnvService,
    private cookie: CookieService
  ) { }
  ngOnDestroy(): void {
    var stripeScript = document.getElementById("stripe-script");
    if (stripeScript) {
      stripeScript.remove();
    }
  }
  ngOnInit(): void {
    // this.invokeStripe();


    this.api.promoObserver$.subscribe(res => {
      if (res) {
        if (res === '--Remove--') {
          this.promo = '';
        } else {
          this.promo = res;
        }
        this.applyPromo();
      }
    })
    this.utils.isHandset().subscribe((handset) => {
      this.isHandset = handset.matches;
    });

    this.deviceHeight = window.innerHeight;
    this.deviceWidth = window.innerWidth;
    this.initStripeDesignPayment();

  }

  formatPhone(event: any) {
    let input = event.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
    if (input.length > 10) input = input.substring(0, 10);
  
    let formatted = '';
    if (input.length > 6) {
      formatted = `(${input.substring(0, 3)}) ${input.substring(3, 6)}-${input.substring(6)}`;
    } else if (input.length > 3) {
      formatted = `(${input.substring(0, 3)}) ${input.substring(3)}`;
    } else {
      formatted = `(${input}`;
    }
    
    event.target.value = formatted;
  }

  ngAfterViewInit(): void { }
  initStripeDesignPayment() {
    this.api.stripeDesignPayment().subscribe((res: any) => {
      this.desPayResp = res.payment_info;
      this.amount = res.payment_info.amount / 100;
      this.amountEmit.emit(this.amount);
      this.paymentId = res.payment_info.id;
      this.clientSecret = res.payment_info.client_secret;
      const options = {
        clientSecret: this.clientSecret,
        appearance: {
          theme: "stripe",
        },
      };
      this.invokeStripe(options)
    });
  }

  applyPromo() {
    const data = {
      promo: this.promo ? this.promo.toUpperCase() : '',
      payment_id: this.paymentId
    }
    this.api.stripeDesignPayment(data).subscribe((res: any) => {
      this.desPayResp = res.payment_info;
      this.amount = res.payment_info.amount / 100;
      this.promoError = res.promo_details.error_msg;
      this.executeFailedPromo.emit(res.promo_details)
    });
  }

  invokeStripe(options) {
    if (!window.document.getElementById("stripe-script")) {
      const script = window.document.createElement("script");
      script.id = "stripe-script";
      script.type = "text/javascript";
      script.src = "https://js.stripe.com/v3/";
      script.onload = () => {
        this.stripe = (<any>window).Stripe(environment.STRIPE_API_KEY);
        this.elements = this.stripe.elements(options);

        const paymentElement = this.elements.create('payment', {
          fields: {
            billingDetails: "auto" // This lets Stripe handle when to show the phone number
          }
        });



        paymentElement.mount("#payment-element");
        this.showPhone = true;

      };
      window.document.body.appendChild(script);

    }
  }

  async pay() {
    if(this.phone.valid){
    this.paymentInProgress = true;
    this.confirmPayment();
    this.saveDesignBookings();
    } else {
      this.phone.markAllAsTouched();
    }
  }
  saveDesignBookings() {
    let data = {
      room_id: this.roomId,
      phone: this.phone.value.replace(/\D/g, "")
    }
    this.api.updateDesignBookings(data).subscribe(res => {

    })
  }
  async confirmPayment() {
    let user = JSON.parse(localStorage.getItem("user"));
    const phoneNumber = (document.getElementById("phone-number") as HTMLInputElement).value;
    let elements = this.elements;
    const { error, paymentIntent } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://seededhome.com/design/book",
        payment_method_data: {
          billing_details: {
            name: user.firstName + " " + user.lastName,
            email: user.email,
            phone: phoneNumber.replace(/\D/g, ""),
            address: {
              country: 'US'
            }
          },
        },
      },
      redirect: "if_required",
    }, err=>{
      this.paymentInProgress =false
    });

    let data = {
      room_id: this.roomId,
      payment_id: paymentIntent.id,
      status: paymentIntent.status,
      payment_method: paymentIntent.payment_method,
      promo: this.promo ? this.promo.toUpperCase() : ''
    };

    this.api.stripeCustomerCreate(data).subscribe(
      (res: any) => {
        if (res) {
          this.paymentInProgress = false;
          if (res.status) {
            this.executePayment.emit(res);
          } else {
            this.paymentInProgress = false;
            this.paymentError =
              " Please try again or use a different payment method.";
          }
        }
      },
      (err) => {
        this.paymentInProgress = false;
        this.paymentError =
          " Please try again or use a different payment method.";
      }
    );
  }
}
