import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "src/app/shared/services";

@Component({
  selector: "app-mail-listing",
  templateUrl: "./mail-listing.component.html",
  styleUrls: ["./mail-listing.component.less"],
  providers: [DatePipe],
})
export class MailListingComponent implements OnInit {
  mailingData: MailingData = new MailingData();
  scheduleList: any[] = [];
  time = "";
  isLive = false;
  innerWidth: number;
  isTestRow = false
  originalScheduleList: any[]=[];
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private snackbar: MatSnackBar
  ) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.scheduleList.forEach((f) => {
      f.isEdit = false;
    });
    this.get();
  }

  reloadPage() {}

  showOnlyTest(){
    this.isTestRow = !this.isTestRow;
    if(this.isTestRow){
    this.scheduleList = this.originalScheduleList.filter(f=>f.is_test===1);
    } else {
      this.scheduleList = this.originalScheduleList.filter(f=>f.is_test!==1);

    }
  }

  get() {
    this.api.getEmailTemplateSchedule().subscribe((res: any) => {
      this.scheduleList = res;
      this.scheduleList.forEach((f) => {
        f.isEdit = false;
        f.template = f.email_template;
        f.schedule = this.getPstTime(f.schedule_date);
        f.time = f.schedule ? f.schedule.toLocaleTimeString().slice(0, -3) : "";
        f.copyIcon = false
      });
      this.originalScheduleList = JSON.parse(JSON.stringify(this.scheduleList)); 
      
      if(this.isTestRow){
        this.scheduleList = this.originalScheduleList.filter(f=>f.is_test===1);
        } else {
          this.scheduleList = this.originalScheduleList.filter(f=>f.is_test!==1);
    
        }

    });
  }
  getPstTime(date: any) {
    let dateObj: Date = typeof date == "string" ? new Date(date) : date;
    if (dateObj) {
      let offset = 7 * 60 * 60 * 1000;
      dateObj.setTime(dateObj.getTime() - offset);
      return dateObj;
    }
  }
  showCopy(i){
this.scheduleList.forEach(f=>f.copyIcon = false);
this.scheduleList[i].copyIcon = true;

  }
  schedule() {
    const sData = new MailingData();
    sData.name = this.mailingData.name;
    sData.template = this.mailingData.template;
    sData.segment = this.mailingData.segment;

    // Adjust for timezone difference
    // const timezoneOffset =
    //   new Date(this.mailingData.date).getTimezoneOffset() * 60000; // in milliseconds
    // const localDate = new Date(
    //   new Date(this.mailingData.date).getTime() - timezoneOffset
    // );

    // Format the date using datePipe and concatenate time
    // const formattedDate = this.datePipe
    //   .transform(localDate, "yyyy/MM/dd")
    //   .concat(` ${this.time}:00`);

    //(this.datePipe.transform(new Date(this.mailingData.date).to, 'yyyy/MM/dd'))
    const PData = this.mailingData.date.concat(` ${this.time}:00`)
    const mDate =  this.convertUTCtoPST(PData)

    let data = {
      name: sData.name,
      template: sData.template,
      schedule: mDate,
      segment: sData.segment,
      is_test: !this.isLive,
    };
      console.log(data);
      this.get()
    this.api.saveEmailTemplateSchedule(data).subscribe((res) => {
      console.log(res);
      if(res && res.status){
      this.snackbar.open("Schedule saved successfully", "x");
      this.mailingData = new MailingData();
      this.get();
      } else {
      this.snackbar.open("API Error", "x");
      }
    });
  }
  convertToPSTDate(dateString: string): string {
    const date = new Date(dateString);
    // Convert to PST by adjusting timezone offset
    const offset = date.getTimezoneOffset() / 60; // Gets the timezone difference in hours
    date.setHours(date.getHours() - offset + 8); // Adjusting to PST (UTC -8)
    return date.toISOString()//.split('T')[0]; // Returns date in YYYY-MM-DD format
  }

  convertUTCtoPST(utcDate: string): string | null {
    return this.datePipe.transform(utcDate, 'yyyy-MM-dd HH:mm:ss', 'PST');
  }
  
  saveFromTable(s) {
    const mDate = this.datePipe
      .transform(new Date(s.date), "yyyy/MM/dd")
      .concat(` ${s.time}:00`);

    let data = {
      name: s.name,
      template: s.template,
      schedule: this.getServerDate(mDate),
    };
    this.api.saveEmailTemplateSchedule(data).subscribe((res) => {
      s.isEdit = false;
    });
  }
  cancel(id) {
    this.api.cancelEmailTemplateSchedule(id).subscribe((res) => {
      this.snackbar.open("Schedule cancelled successfully", "x");
      this.get();
    });
  }
  copy(s){
this.mailingData = s;
this.mailingData.segment = s.email_group;
const date = new Date(s.schedule_date).getTime() <= new Date().getTime() ? new Date(): new Date(s.schedule_date);
this.mailingData.date = this.datePipe.transform(date, 'yyyy-MM-dd');
this.time = s.schedule_date.split(' ')[1]
;
  }
  getColor(status: string) {
    if (status.toUpperCase() == "DELIVERED") {
      return {
        "background-color": "#4daf50",
        color: "white",
        height: "22px",
        "border-radius": "8px",
      };
    } else if (status.toUpperCase() == "CANCELED") {
      return {
        "background-color": "#ed5f5a",
        color: "white",
        height: "22px",
        "border-radius": "8px",
      };
    } else if (status.toUpperCase() == "SCHEDULED") {
      return {
        "background-color": "#05a8f4",
        color: "white",
        height: "22px",
        "border-radius": "8px",
      };
    }
  }
  getServerDate(date: any): any {
    const y = new Date(date).toISOString();
    return y;
  }
}
class MailingData {
  name: string;
  template: string;
  date: any;
  segment: string;
}
