import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
} from "../shared/services";
import { ChatService } from "../shared/services/api/chat.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.less"],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  isHandset = false;
  messageList: any[] = [];
  isChatMob = true;
  isProductsMob = false;

  message: string = "";
  queryLoading: boolean;
  userId: any;

  genomeName: string = "";
  genomeDescription: string = "";
  genomeImage: string = "";
  subStylesList: any[] = [];
  quizId: any;
  conversation_id: string = "";
  historyList: any[] = [];
  currentHistory: any;

  groupedItems: { [key: string]: any[] } = {};
  finalChatHistory: any[] = [];
  currentMonth = new Date().toLocaleString("default", { month: "long" });
  newChatClicked: boolean;
  isLeftPanelOpened = false;
  promptSearchList: any[] = [];
  assignData: boolean;
  hasProducts: boolean = false;
  pinBoardList: any[] = [];
  showPinBoard = false;
  isRightPanelOpened = false;
  title: any;
  cartProduct: number;
  conversationStage = "";
  showTabs = false;
  loaded: boolean;
  pCounter = 1;
  showChoice: boolean;
  isStyleQuizCompleted: boolean = true;
  plpLoading: boolean;
  routerQuery: string;
  routerCategory: string; 
  private destroy$ = new Subject();
  bottomScrolled: boolean;
  isSideNavOpened: boolean = true;
  previewPinned = false;
  @ViewChild('msgContain') msgContain!: ElementRef;

  private isResizing: boolean = false;
  private minWidth: number = 100;
  isPrdPreviewOpened = false;
  productDetails: string;
  @ViewChild('bottomSheet') bottomSheet:TemplateRef<any>; 
  finalProducts: any[]=[];
  allItems: boolean;
  loadMoreBtn: boolean;
  styleFilters: string;
  stopApiLoading: boolean;
  chatSubscription: Subscription;
  isProductsTabClicked = false;
  currentPosition: boolean;

  constructor(
    public chatService: ChatService,
    private apiService: ApiService,
    private location: Location,
    private router: Router,
    private actRouter: ActivatedRoute,
    private renderer: Renderer2,
    private matDialogUtils: MatDialogUtilsService,
  private activatedRouter: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private eventEmitterService: EventEmitterService,
    public _bottomSheet: MatBottomSheet
  ) {
  if(this.chatService.brandList.length==0) this.getBrands();

  }
  getBrands() {
    this.apiService.getAllBrandNames().subscribe((res) => {
      this.chatService.brandList = res;
    });
  }

  ngOnInit(): void {
 
    if(sessionStorage.getItem('promptCategory') && sessionStorage.getItem('promptCategory')!=='null'){
   
      this.chatService.promptCategory = sessionStorage.getItem('promptCategory');
      this.allItems = true;
      this.chatService.tabObs.next(true);
    }
    this.actRouter.queryParamMap.subscribe(params => {
      this.routerQuery = params.get('query');
      this.routerCategory = params.get('category');
      if(this.routerQuery){
        this.chatService.promptCategory = this.routerCategory.substring(1);
        sessionStorage.setItem('promptCategory',this.chatService.promptCategory);
        this.chatService.tabObs.next(true);
        this.allItems = true;
        this.chatService.query = this.routerQuery;
      };
    });
    if (
      this.chatService.promptSearchList &&
      this.chatService.promptSearchList.length > 0
    ) {
      this.promptSearchList = this.chatService.promptSearchList;
    } else {
      this.getPromptsSearch();
    }
    this.cartProduct = parseInt(localStorage.getItem("cart"));

    this.eventEmitterService.updateCart.subscribe((payload) => {
      this.cartProduct = parseInt(localStorage.getItem("cart"));
    });

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
      });
    this.conversation_id = this.activatedRouter.snapshot.params.id;
    if (this.isLoggedIn || true) {
      this.userId = JSON.parse(localStorage.getItem("user")).id;
      this.getAllConverstations();
    }

    if (this.chatService.query && !this.conversation_id) {
      this.chat(this.chatService.query);
    } else {
      this.newChatClicked = true;
    }
    setTimeout(() => {
      if (this.chatService.promptCategory) {
        if(this.isLoggedIn || this.chatService.query ){
        this.isRightPanelOpened = true;
        this.isSideNavOpened = false;
        } else { 
        }
      }

    }, 1500);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    if(this.isRightPanelOpened && this.msgContain){
    const rect = this.msgContain.nativeElement.getBoundingClientRect();
    if (event.offsetX > rect.width - 10) {
      this.isResizing = true;
      this.renderer.setStyle(document.body, 'cursor', 'ew-resize');
      this.renderer.addClass(document.body, 'disable-selection');
    }
  }
  }

  // Mouse move event
  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if(this.isRightPanelOpened && this.msgContain){
 
    const rect = this.msgContain.nativeElement.getBoundingClientRect();
    const maxWidth = 1500; // Set your max width

    // If resizing, adjust the width
    if (this.isResizing) {
      const newWidth = Math.min(Math.max(event.clientX - this.msgContain.nativeElement.getBoundingClientRect().left, this.minWidth), maxWidth);
      this.renderer.setStyle(this.msgContain.nativeElement, 'width', `${newWidth}px`);
    } else {
      // Check if mouse is near the right edge and change cursor
      if (event.clientX > rect.right - 10 && event.clientX < rect.right + 10) {
        this.renderer.setStyle(this.msgContain.nativeElement, 'cursor', 'ew-resize');
      } else {
        this.renderer.removeStyle(this.msgContain.nativeElement, 'cursor');
        // this.renderer.removeClass(document.body, 'disable-selection');
      }
    }
  }
  }

  // Mouse up event to stop resizing
  @HostListener('window:mouseup')
  onMouseUp(): void {
    if(this.isRightPanelOpened && this.msgContain){
    if (this.isResizing) {
      this.isResizing = false;
      this.renderer.removeStyle(document.body, 'cursor'); // Reset the cursor
      this.renderer.removeClass(document.body, 'disable-selection');
    }
  }
  }
  get deviceWidth(): number {
    return window.outerWidth;
  }

  get innerWidth(): number {
    return window.innerWidth;
  }

  get deviceHeight(): number {
    return window.innerHeight;
  }

  get msgBoxWidth(): string {
    return this.msgContainWidth + "px";
  }

  get msgContainWidth(): number {
    const element = document.getElementById("headContain");
    if(element){
    const rect = element.getBoundingClientRect();
    return rect.width;
    }
    return 0;
  }

  get searchContainWidth(): number {
    const element = document.getElementById("searchContainer");
    const rect = element.getBoundingClientRect();
    return rect.width;
  }

  get msgIdContainWidth(): number {
    const element = document.getElementById("messageIdentifier0");
    if (element) {
      const rect = element.getBoundingClientRect();
      return rect.width;
    } else {
      return 600;
    }
  }

  getPromptsSearch() {
    this.chatService.getPromptSearch().subscribe((resp: any[]) => {
      this.promptSearchList = resp.find(
        (f) => f.type === "primary"
      ).prompt_details;
    });
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }

  sampleChat(data) {
    this.newChatClicked = false;
    this.chatService.query = data;
    this.chat(data);
  }

  loadMoreProducts(event) {
    // this.queryLoading = true;
    this.loaded = false;
    let obj = {
      conversation_id: this.conversation_id,
      message: event.message.replace("\n", ""),
      is_new_conversation: this.messageList.length > 0 ? false : true,
      only_retrieve: true, // for load more
      products: event.sub_product, // for load more
    };
    this.messageList.push({
      role: "user",
      message: event.message,
      conversation_stage: "",
      room: "",
      product_details: [],
      only_retrieve: true, // for load more
      sub_product: event.sub_product, // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: false,
    });
    if (event.fromBtn) {
      this.pCounter = this.pCounter + 1;
    }
    this.chatRequest(obj,true);
  }

  chat(data) {
    let obj = {
      conversation_id: this.conversation_id,
      message: data.replace("\n", ""),
      only_retrieve: false,
      is_new_conversation: this.messageList.length > 0 ? false : true,
    };
    this.messageList.push({
      role: "user",
      message: data,
      conversation_stage: (this.messageList?.length>0 && this.messageList[this.messageList.length-1].conversation_stage==='features' )
      ? 'features':'',
      room: "",
      product_details: [],
      only_retrieve: false, // for load more
      sub_product: [], // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: false,
    });
    this.chatRequest(obj);
  }

  chatRequest(obj,loadMore=false) {
    this.message = "";

    this.scrollToQuery();
    if(!loadMore){
    this.queryLoading = true;
    }
    this.messageList.push({
      role: "assistant",
      message: ``,
      conversation_stage: (this.messageList?.length>0 && this.messageList[this.messageList.length-1].conversation_stage==='features' )
      ? 'features':'',
      room: "",
      product_details: [],
      only_retrieve: obj.only_retrieve, // for load more
      sub_product: [], // for load more
      product_count: 0,
      quiz_id: "",
      genome_ids: [],
      showStyle: false,
      showLoader: true,
    });
    let input: HTMLInputElement = document.getElementById(
      "chatInput"
    ) as HTMLInputElement;
    if (input) {
      input.value = null;
    }

  this.chatSubscription =  this.chatService.chatRequest(obj).pipe(takeUntil(this.destroy$)).subscribe(
      (resp: any) => {
        if (resp) {
          setTimeout(() => { 
            this.conversation_id = resp.conversation_id;
            this.conversationStage = resp.conversation_stage;
            this.title = resp.title;
            this.location.replaceState(`/c/${this.conversation_id}`);
            let room = "living";
            this.messageList[this.messageList.length - 1].message =
              resp.message[0];
            this.messageList[this.messageList.length - 1].only_retrieve =
              obj.only_retrieve;
            this.messageList[this.messageList.length - 1].showLoader = false;
            this.messageList[this.messageList.length - 1].conversation_stage =
              resp.conversation_stage;
            if (resp.room) {
              room = resp.room;
            }
            this.messageList[this.messageList.length - 1].room = room;
            this.messageList[this.messageList.length - 1].quiz_id =
              resp.quiz_id;
              let sIndex=-1;
            if (resp.jsondata && resp.jsondata.product) {
            this.stopApiLoading = false; 
               sIndex = this.messageList.findIndex(
                (f) => f.conversation_stage.toLocaleLowerCase() === "products"
              );
              let count = this.messageList.filter(
                (f) => f.conversation_stage.toLocaleLowerCase() === "products"
              ).length;

              // console.log(
              //   count,
              //   this,
              //   this.messageList,
              //   sIndex,
              //   resp.jsondata.product
              // );
              this.messageList[this.messageList.length - 1].sub_product = [];

              if (count > 1) {
                resp.jsondata.product.forEach((f) => {
                  this.messageList[sIndex].product_details.push(f);
                });
                this.messageList[sIndex].product_details = JSON.parse(
                  JSON.stringify(this.messageList[sIndex].product_details)
                );
                this.messageList[sIndex].product_count =
                  this.messageList[sIndex].product_count + 1;

                if (resp.jsondata.sub_product) {
                  this.messageList[sIndex].sub_product =
                    resp.jsondata.sub_product;
                }
              } else {
                this.messageList[this.messageList.length - 1].product_details =
                  resp.jsondata.product;
                this.messageList[this.messageList.length - 1].product_count =
                  this.messageList[this.messageList.length - 1].product_count +
                  1;
                if (resp.jsondata.sub_product) {
                  this.messageList[this.messageList.length - 1].sub_product =
                    resp.jsondata.sub_product;
                }
              }
            }
            if (resp.conversation_stage.toLowerCase() === "products") {
              resp.jsondata.product.forEach((f) => {
                f["pinned"] = false;
              });

              this.hasProducts = true;
              this.isStyleQuizCompleted = true;
    
              this.isSideNavOpened = false;
              this.chatService.tabObs.next(false);
              this.messageList[this.messageList.length - 1].genome_ids =
                resp.genome_ids;
              // this.chatService.promptCategory = null;
            }
            if (resp.conversation_stage.toLowerCase() === "style") {
              this.showChoice = true;
              this.messageList[this.messageList.length - 1].genome_ids =
                resp.genome_ids;
            }
            if (resp.conversation_stage.toLowerCase() === "products") {
              this.showTabs = true;
              this.isStyleQuizCompleted = true;
              if(!this.isProductsTabClicked){ // don't do auto toggles to the product tab when new products load
              this.isProductsMob = true;
              }
              this.isProductsTabClicked = true;
              this.chatService.tabObs.next(false);
              // this.chatService.promptCategory = null; 
              // if(this.isHandset){
              // window.scrollTo(0, 0);
              // }
            }
            this.quizId = resp.quiz_id;
            this.chatService.quizCategory = room;
            this.scrollToResponse();
            if (resp.jsondata.product && resp.jsondata.product.length === 0) {
              this.chatService.noMoreProducts = true;
              this.chatService.viewAllBtn = true;
              this.stopApi();
            } else {
              this.chatService.noMoreProducts = false; 
              this.loadProducts(this.messageList[sIndex>0? sIndex: this.messageList.length-1].product_details);
            }
            this.queryLoading = false;  
            if(this.isHandset){
              this.currentPosition = this.isSectionScrolledToBottom(); 
            } else {
              this.currentPosition = this.isSectionScrolledToBottom();

            }
          }, 0);
          // this.getAllConverstations(false);
        }
      },
      (err) => {
        this.messageList[this.messageList.length - 1].showLoader = false;
        this.queryLoading = false;
      }
    );
  }
 
  scrollToQuery() {
    setTimeout(() => {
      let name = document.getElementById("msgContainer");
      name.scrollTo({
        left: 0,
        top: name.scrollHeight - 400,
        behavior: "smooth",
      });
    }, 0);
  }

    isSectionScrolledToBottom( ): boolean {
      if(this.isHandset){ 
  
    return document.documentElement.scrollHeight - document.documentElement.scrollTop <= (document.documentElement.clientHeight+50);
      } else{
        const section = document.getElementById('rightPanel');
        if (!section) return false;
      
        return section.scrollHeight - section.scrollTop <= (section.clientHeight+50);
      }
  }
  

  scrollToResponse() {
    setTimeout(() => {
      if(this.currentPosition){ 
        window.scrollTo(0, this.chatService.scrollData.scrollHeight);
        this.chatService.scrollData.scrollElement.scrollTo(0,this.chatService.scrollData.scrollHeight)
      }
      let name = document.getElementById("msgContainer");
      if (
        this.messageList[this.messageList.length - 1].conversation_stage ===
        "style"
      ) {
        let he = name?.scrollHeight - (this.deviceHeight - 100) - 490;
        name?.scrollTo({
          left: 0,
          top: he,
          behavior: "smooth",
        });
      } else if (
        this.messageList[
          this.messageList.length - 1
        ].conversation_stage.toLowerCase() === "products"
      ) {
        let he = name?.scrollHeight - (this.deviceHeight - 100) - 490;
        name?.scrollTo({
          left: 0,
          top: he,
          behavior: "smooth",
        });
      } else {
        name?.scrollTo({
          left: 0,
          top: name?.scrollHeight - 400,
          behavior: "smooth",
        });
      }
      let input: HTMLInputElement = document.getElementById(
        "chatInput"
      ) as HTMLInputElement;
      if (input) {
        input.focus();
      }
    }, 0);
  }

  mapMyStyle() {
    this.isRightPanelOpened = true;
    this.isSideNavOpened = false;
    this.showChoice = false;
    this.isStyleQuizCompleted =false;
  }
  skip() {
    this.showChoice = false; 
    this.isStyleQuizCompleted =true;

    let obj = {
      conversation_id: this.conversation_id,
      message: "style:",
      only_retrieve: false,
      is_new_conversation: false,
    };
    this.messageList.push({
      role: "user",
      message: "style:",
      conversation_stage: (this.messageList?.length>0 && this.messageList[this.messageList.length-1].conversation_stage==='features' )
      ? 'features':'',
      room: "",
      product_details: [],
      only_retrieve: false, // for load more
      sub_product: [], // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: false,
    });
    this.chatRequest(obj);
    this.isStyleQuizCompleted = true;
  }

  checkQueryLoading(e) {
    if(sessionStorage.getItem('promptCategory') && sessionStorage.getItem('promptCategory')!=='null'){
      this.chatService.promptCategory = sessionStorage.getItem('promptCategory');
      this.chatService.tabObs.next(true);
      // this.isRightPanelOpened = true;
    }else {
    }
    this.isRightPanelOpened = false;
    this.messageList.push({
      role: "assistant",
      message: ``,
      conversation_stage: (this.messageList?.length>0 && this.messageList[this.messageList.length-1].conversation_stage==='features' )
      ? 'features':'',
      room: "",
      product_details: [],
      only_retrieve: false, // for load more
      sub_product: [], // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: true,
    });
    this.queryLoading = true;

  }
  getMessage(data) {
    if (data.status) {
      this.isStyleQuizCompleted = true; 
      if(sessionStorage.getItem('promptCategory') && sessionStorage.getItem('promptCategory')!=='null'){
        this.chatService.promptCategory = sessionStorage.getItem('promptCategory');
        this.chatService.tabObs.next(true);
        this.isRightPanelOpened = true;
      }else {
        this.isRightPanelOpened = false;
      }
      setTimeout(() => {
        this.assignData = true;
      }, 3000);
      this.queryLoading = false;
      this.conversationStage = "";
      setTimeout(() => {
        this.messageList[this.messageList.length - 1].showLoader = false;
      }, 2000);
      this.messageList[this.messageList.length - 1].message =
        data.chat && data.chat.message ? data.chat.message[0] : "";
      this.messageList[this.messageList.length - 1].showStyle = true;
    }
  }
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  sendQuery() {
    this.chat(this.message);
    const textarea = document.getElementById("chatInput");
    textarea.style.height = "72px";
    setTimeout(() => {
      let msgContainerLeft = document.getElementById('msgContain') as HTMLElement;
      if(msgContainerLeft){
        msgContainerLeft.scrollTo({
          top: msgContainerLeft.scrollHeight,
          behavior: "smooth"
      });
      }  
      if(this.isHandset){
      window.scrollTo(0,document.body.scrollHeight);
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;   
      } 
    }, 100);

    
  }
  stopApi(){
    this.chatSubscription.unsubscribe();
    this.stopApiLoading = true;
    this.chatService.chatApiLoading$.next(true);
    this.queryLoading = false;
    this.isProductsTabClicked = false

  }
  clickPrdTab(){
    this.isChatMob=false;
    this.isProductsMob=true;
    // this.isProductsTabClicked=true;
    if(!this.chatService.promptCategory){
      this.stopApiLoading =false
    }
  }
  getAllConverstations(assign = true) {
    // this.assignData = false;

    this.chatService.getUserConvSummary().subscribe((resp: any) => {
      this.historyList = resp;
      this.groupByDate();
    });

    let data = JSON.parse(localStorage.getItem("recentChat"));
    this.getStyleReportOnLoad();

    if (data && data.conversation_id === this.conversation_id) {
      if (assign) {
        this.assignData = true;
      }
      this.formatChat(data);
    } else {
      if (this.conversation_id) {
        this.chatService
          .viewConversationsById(this.conversation_id)
          .subscribe((resp: any) => {
            if (assign) {
              this.assignData = true;
              if (resp && resp.conversation) {
                this.formatChat(resp.conversation);
              }
            } else {
              this.getStyleReportOnLoad();
            }
          });
      }
    }
  }

  getStyleReportOnLoad() {
    if (this.conversation_id) {
      this.chatService.getStyleReportByConvId(this.conversation_id).subscribe(
        (res: any) => { 
          if (res && res.genome && res.genome.length > 0) {
            this.genomeName = res.genome[0].genome_name;
            this.genomeDescription = res.genome[0].genome_description;
            this.genomeImage = res.genome[0].genome_image;
            this.subStylesList = res.substyles;
            this.styleFilters = 'style:' + res.substyles.map((substyle) => substyle.style_value).join(","); 
            console.log(this.styleFilters,'STYLESFILTER')
          }
        },
        () => {}
      );
    }
  }



  getConv(h) {
    if (h.conversation_id) {
      this.isRightPanelOpened = false;
      this.isProductsMob = false;
      this.showTabs = false;
      this.chatService
        .viewConversationsById(h.conversation_id)
        .subscribe((resp: any) => {
          this.hasProducts = false;
          this.finalProducts = []; 
          this.chatService.productsUpdate$.next([]);
          if (resp && resp.conversation) {
            this.formatChat(resp.conversation);
          }
        });
    }
  }

  formatChat(conv: any) {
    if (conv) {
      this.assignData = true;
      localStorage.setItem("recentChat", JSON.stringify(conv));
      let convC = JSON.parse(JSON.stringify(conv));
      let chats = convC.chats; 
      chats
        .filter((f) => f.conversation_stage.toLowerCase() === "products")
        .forEach((f) => {
          f["product_count"] = 0;
          f["product_details"] = [];
        });

      let sIndex = chats.findIndex(
        (f) => f.conversation_stage.toLocaleLowerCase() === "products"
      );
      let productChat = chats.filter(
        (f) => f.conversation_stage.toLocaleLowerCase() === "products"
      );
      let count = chats.filter(
        (f) => f.conversation_stage.toLocaleLowerCase() === "products"
      ).length;

      if (count > 1) {
        let executed = false;
        productChat.forEach((chat) => {
          if (chat.products) {
            chat.products.forEach((f) => {
              productChat[0].product_details.push(f);
            });
            if (chat[sIndex]) {
              chat[sIndex].product_details = JSON.parse(
                JSON.stringify(productChat[0].product_details)
              );
            } else {
              if (!executed) {
                chat.product_details = JSON.parse(
                  JSON.stringify(productChat[0].product_details)
                );
              }
              executed = true;
            }
            productChat[0].product_count = productChat[0].product_count + 1;
          }
        });
        this.loadProducts(productChat[0].product_details);
      } else {
        if(productChat && productChat.length){
        productChat.forEach((chat) => {
          productChat[productChat.length - 1].product_details = chat.products;
          productChat[productChat.length - 1].product_count =
            productChat[productChat.length - 1].product_count + 1;
        });
        this.loadProducts(productChat[productChat.length - 1].product_details);
      }
      }
      this.assignChatData(convC);
    }
  }


  assignChatData(h) {
    this.currentHistory = h;
    this.title = h.title ? h.title : h.conversation_id;
    this.messageList = h.chats;
    if (
      this.messageList.some(
        (s) => s.conversation_stage.toLowerCase() === "products"
      )
    ) {
      // this.chatService.promptCategory = null;
      // this.isRightPanelOpened = true;
      this.isSideNavOpened = false;
      this.isStyleQuizCompleted = true;
      this.showTabs = true;
      // this.getPinBoardList();

      this.hasProducts = true;
    }
    this.chatService.query = h.title;
    this.location.replaceState(`/c/${h.conversation_id}`);
    this.assignData = true; 
  }


  groupByDate() {
    this.groupedItems = {};
    this.historyList.forEach((item) => {
      const date = new Date(item.created_date);
      const monthKey = `${date.toLocaleString("default", { month: "long" })}`;
      if (!this.groupedItems[monthKey]) {
        this.groupedItems[monthKey] = [];
      }
      this.groupedItems[monthKey].push(item);
    });
    this.sortGroupedItemsByDate();
  }
  sortGroupedItemsByDate() {
    this.finalChatHistory = [];
    // Map month names to their corresponding numerical values
    const monthMap: { [key: string]: number } = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    // Convert groupedItems to an array of [monthName, items[]] pairs
    const pairs = Object.entries(this.groupedItems);

    // Sort the pairs based on the numerical value of the month
    pairs.sort((a, b) => {
      return monthMap[b[0]] - monthMap[a[0]];
    });

    // Convert the sorted pairs back to the groupedItems object
    this.groupedItems = {};
    pairs.forEach((pair) => {
      this.finalChatHistory.push({
        key: pair[0],
        value: pair[1],
      });
      this.groupedItems[pair[0]] = pair[1];
    });
  }
  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);

    const intervalx = setInterval(() => {
      const intercomx = document.getElementsByClassName("intercom-app")[0];
      if (intercomx) {
        intercomx.classList.add("dn");
        clearInterval(intervalx);
      }
    }, 500);
  }
  ngAfterViewInit(): void {
    this.hideIntercomIcon();

    document.addEventListener("DOMContentLoaded", function () {
      const textarea = document.getElementById("chatInput");


      console.log(textarea);
      textarea.addEventListener("input", function () {
        this.style.height = "auto";
        this.style.height = this.scrollHeight - 10 + "px";
      });
  

      
    }); 
  }
  openSignupDialog() {
    this.matDialogUtils.openSignupDialog(this.isHandset);
  }

  openSignInDialog() {
    this.matDialogUtils.openSigninDialog(this.isHandset ? "90%" : "35%");
  }
  getStyleReport(res) {
    this.genomeName = res.genome[0].genome_name;
    this.genomeDescription = res.genome[0].genome_description;
    this.genomeImage = res.genome[0].genome_image;
    this.subStylesList = res.substyles;

    this.styleFilters = 'style:' +res.substyles.map((substyle) => substyle.style_value).join(","); 
  }
  startNewChat() {
    this.newChatClicked = true;
    this.messageList = [];
    this.conversation_id = "";
    this.chatService.query = "";
    this.isLeftPanelOpened = false;
    this.location.replaceState(`/chat`);
    this.assignData = false;
    this.isStyleQuizCompleted = false;
    this.title = "";
    this.showPinBoard = false;
    this.hasProducts = false;
    this.pinBoardList = [];
    this.isRightPanelOpened = false;
    this.showTabs = false;
    this.chatService.shuffledImages = [];
    sessionStorage.removeItem('promptCategory'); 
    this.chatService.productsDataPLP = [];
    this.chatService.promptCategory='';
   this.isSideNavOpened = true;
   this.title='';
   this.genomeName='';
   this.finalProducts = [];
   this.chatService.productsUpdate$.next([]);

  }
  startNewChatOnNewTab(){
    this.router.navigate([]).then(() => {
      window.open(`/chat`);

    });
  }

  goToStyle() {
    this.router.navigate([]).then(() => {
      window.open(`/style-quiz/my-style/${this.quizId}`);
    });
  }
  // getPinBoardList() {
  //   this.chatService
  //     .getPinboardList(this.conversation_id)
  //     .subscribe((res: any) => {
  //       if (res.status) {
  //         this.pinBoardList = res.products;
  //       }
  //     });
  // }
  ngOnDestroy(): void {
    this.chatService.shuffledImages = []; 
      this.destroy$.next();  
      this.destroy$.complete();  
      // sessionStorage.removeItem('promptCategory'); 
  }
  goToConv(h) {
    this.chatService.shuffledImages = [];
    this.router.navigate([`/c/${h.conversation_id}`]);
    this.ngOnInit();
  }
  @HostListener("window:scroll", ["$event"])
  onScroll(event): void {
    const element = document.getElementById("rightPanel");
    if (element) { 
  if (element.scrollHeight <= (element.scrollTop + element.offsetHeight + 10)
    &&  !this.chatService.noMoreProducts &&
    !this.chatService.viewAllBtn  && this.allItems
  ) {
        this.plpLoading = true;
      }
     
    }  
    this.chatService.scrollData = {
      isBottomScrolled:false,//this.isScrolledToBottom(),
      scrollElement:element,
      scrollHeight:event.target.scrollHeight || document.documentElement.scrollTop
    }; 

 
    if (this.isScrolledToBottom()) {
      this.chatService.bottomScrolled.next(true);
    } else {
      this.chatService.bottomScrolled.next(false)
    }

  }
  plpLoadingStatus(e) {
    this.plpLoading = e;
  }

  isScrolledToBottom(): boolean {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
 
    // Check if the user has scrolled to the bottom
    return scrollTop + clientHeight >= scrollHeight - 100;
  }

  toggleSideNav(){
    this.isSideNavOpened = !this.isSideNavOpened;
  }
  getSku(event:string){
    this.productDetails = event;
    this.isPrdPreviewOpened=true;
    if(this.isHandset){
      this.openBottomSheet();
    }
  }

  openBottomSheet(){
    this.isPrdPreviewOpened=true;
    this._bottomSheet.open(this.bottomSheet);
  }

  emitPin(p){
    this.previewPinned = p;
  }

  loadProducts(products){
    // if (!(products.length > 0)) {
    //   this.chatService.noMoreProducts = true;
    // }
    if (products && products.length>0) {
      this.allItems = false;
      if (
        !products.every(
          (f) => f.rationale && (f.rationale.ai_response.startsWith("No.") || f.rationale.ai_response.startsWith("No,"))
        )
      ) {
        this.loadMoreBtn = true;
      } else {
        this.loadMoreBtn = false;
      }
      if (
        products.every(
          (f) => f.rationale && (f.rationale.ai_response.startsWith("No.") || f.rationale.ai_response.startsWith("No,"))
        )
      ) {
        this.chatService.viewAllBtn = true;
      } else {
        this.chatService.viewAllBtn = false;
      }

      products.forEach((e) => {
        e["pinned"] = false;
        e["display"] = true;
        if (e.rationale && e.rationale.ai_response) {
          e.llmExpanded = this.isHandset ? false : true;
          if (e.rationale.ai_response.startsWith("No.") || e.rationale.ai_response.startsWith("No,")) {
            e["display"] = false;
          }

          if (
            e.rationale.ai_response.startsWith("Yes.") ||
            e.rationale.ai_response.startsWith("Yes,")
          ) {
            e["display"] = true;
            e.rationale.ai_response = e.rationale.ai_response.startsWith("Yes.")
              ? e.rationale.ai_response.replace("Yes. ", "").trim()
              : e.rationale.ai_response.replace("Yes, ", "").trim();
            e.rationale.ai_response =
              e.rationale.ai_response.charAt(0).toUpperCase() + e.rationale.ai_response.slice(1);
          }
        }
      });
      products
        .filter((f) => f.display)
        .forEach((f) => {
          this.finalProducts.push(f);
        });
      if (products.length > 0) {
        if (this.finalProducts.length > 0) {
          this.isRightPanelOpened = true;
          this.chatService.productsUpdate$.next(this.finalProducts);
        } else {
          this.isRightPanelOpened = false;
        }
        this.chatService.noMoreProducts = false;
      } 
    } else {
      if(!this.allItems){
        this.chatService.noMoreProducts = true;
      }
      }
  }
  setAllItems(e){
    this.allItems = e;
  }
}
