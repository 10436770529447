// animations.ts
import { trigger, state, style, transition, animate } from '@angular/animations';

export const smoothFade  = trigger('smoothFade', [
    transition(':enter', [
      style({ height: '0px', opacity: 0 }), // Start collapsed and hidden
      animate(
        '300ms ease-out',
        style({ height: '*', opacity: 1 }) // Expand to full height and fade in
      )
    ]),
    transition(':leave', [
      style({ height: '*', opacity: 1 }), // Start expanded and visible
      animate(
        '300ms ease-in',
        style({ height: '0px', opacity: 0 }) // Collapse and fade out
      )
    ])
  ]);
