import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {ApiService} from '../../../../shared/services';
import {MatDialog} from '@angular/material/dialog';
import { IActiveProduct, IProduct } from 'src/app/shared/models';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-product-status-mobile',
    templateUrl: './product-status-mobile.component.html',
    styleUrls: ['./product-status-mobile.component.less'],
    providers:[DatePipe]
})
export class ProductStatusMobileComponent implements OnInit, OnChanges {


    @Input() productSku;
    @Input() product: IProduct;
    @Input() activeProduct:IActiveProduct;
    @Input() isDrop = ''; 
    @ViewChild('dialog', {static: true, read: TemplateRef}) matDialogTemplate: TemplateRef<any>;
    @Input() zipCode = '';
    statusMessage = 'Enter your zip code for delivery estimate';
    numberOfRetries = 3;
    currentTryNumber = 1;
    statusReceived = false;
    showZipCodeBox = true;
    fetchingInfo = false;
    shipZipAvailable: boolean; 
    showIsDrop0Content=false;
    // @Output() showIsDrop0: EventEmitter<string> = new EventEmitter();
    
    constructor(
        private apiService: ApiService,      
    private matDialog: MatDialog,
    private datePipe:DatePipe
    ) {
    }
    
    ngOnInit() {
        const userZipcode = localStorage.getItem('userZipCode');
        if (userZipcode) {
            this.zipCode = userZipcode;
            this.getShippingInfo();
        }
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.productSku && !changes.productSku.firstChange) {
    
            this.getShippingInfo();
        }
    }
    
    getShippingInfo() {
        if (this.zipCode && this.productSku) {
            if(this.productSku.includes('-')){
                this.productSku = this.productSku.split('-')[0];
            } 
            
            this.fetchingInfo = true; 
            localStorage.setItem('userZipCode', this.zipCode);
            const headers = new HttpHeaders({
                Accept: 'application/json',
            }
            );
    
            if (this.isDrop === '2') {
                const userZipcode = localStorage.getItem('userZipCode');
                // this.getProductShippingStatus();
                if (userZipcode) {
                    this.apiService.getShipzipStatus(userZipcode, this.productSku).subscribe((resp: any) => {
                        if (resp.status == true) { 
                            this.showZipCodeBox=false;
                            this.fetchingInfo=false;
                            this.shipZipAvailable = true;
                            this.showIsDrop0Content = true; 
                        }  else {
                            this.showZipCodeBox=false;
                            this.fetchingInfo=false;
                            this.shipZipAvailable = true;
                            this.showIsDrop0Content = true; 
                        } 
                    })
                }
            } else {
                this.showIsDrop0Content = false; 
                this.getProductShippingStatus();
            }
        }
    }
    getProductShippingStatus() {
        this.apiService.getProductShippingStatus(this.zipCode, this.productSku, this.product.brand)
            .then((response: any) => {
                if (response.error) {
                    if (this.currentTryNumber <= this.numberOfRetries) {
                        setTimeout(() => this.getShippingInfo(), 500);
                        this.currentTryNumber++;
                    } else {
                        this.statusMessage = 'Status unavailable. Please check back again later.';
                        this.fetchingInfo = false;
                    }
                } else {
                    this.statusReceived = true;
                    this.showZipCodeBox = false;
                    this.fetchingInfo = false;
                    this.shipZipAvailable =true;
                    // this.statusMessage = response.deliveryOptions[0].deliveryMessage;
                    if (this.product.brand === 'westelm') {
                        if (response.availabilityMessage.includes('In Stock')) {
                            this.statusMessage = "In Stock & Ready to Ship." + " " + response.deliveryOptions[0].deliveryMessage;
                        }  else if (response.availabilityMessage) {
                            const minDelDate = this.datePipe.transform(
                              response.deliveryOptions[0].minimumDeliveryDate,
                              "mmm. dd"
                            );
                            const maxDelDate = this.datePipe.transform(
                              response.deliveryOptions[0].maximumDeliveryDate,
                              "mmm. dd"
                            );
                            // this.statusMessage = response.deliveryOptions[0].deliveryMessage;
                            this.statusMessage = `Estimated delivery between ${minDelDate} and ${maxDelDate}`;
                          } else {
                            this.statusMessage = "Delivery Estimate Unavailable";
                          }
                    } else if (this.product.brand === 'cb2' || this.product.brand === 'cab') {
                        if (response.isBackOrdered) {
                            this.statusMessage = response.backOrderedMessage + " " + response.backOrderedMessageDate + " " + "for zip code:";
                        } else {
                            this.statusMessage = response.onlineAvailableMessage;
                        }
                    }
                    // else if (this.product.site.toUpperCase() === 'CAB') {
    
                    // }
                    console.log(response, 'stataus response')
                }
            }, err => {
                if (this.currentTryNumber <= this.numberOfRetries) {
                    setTimeout(() => this.getShippingInfo(), 500);
                    this.currentTryNumber++;
                } else {
                    this.statusMessage = 'Status unavailable. Please check back again later.';
                    this.fetchingInfo = false;
                }
            });
    }
    
    openMatDialog() {
        this.matDialog.open(this.matDialogTemplate, {
            disableClose: true,
            width: '95vw'
        });
    }
    }




