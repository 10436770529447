import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService, EventEmitterService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-design-sign-up",
  templateUrl: "./design-sign-up.component.html",
  styleUrls: ["./design-sign-up.component.less"],
})
export class DesignSignUpComponent implements OnInit, AfterViewInit {
  isHandset: any;
  logoPath: string;
  email = new FormControl("", [Validators.required, Validators.email]);
  name = new FormControl("", [Validators.required]);

  password = new FormControl("", [Validators.required]);
  quizId: string;

  emailAvailable: boolean;
  showAuth: boolean;
  roomId: any;
  selectedDept: any;
  errorMsg: any;
  isLoading: boolean;
  defaultEmail: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private env: EnvService,
    private router: Router,
    private cookie: CookieService,
    private api: ApiService,
    private eventService: EventEmitterService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
      });

    this.logoPath = this.env.logoPathBlack;
  }

  ngOnInit(): void {
    if(this.isLoggedIn){
      this.router.navigate([`/style-quiz/my-style/${this.api.quizId}`]);

    }
    this.selectedDept = this.api.quizCategory;
    this.quizId = this.api.quizId; 
    if(JSON.parse(localStorage.getItem("user")) ){
      this.defaultEmail = JSON.parse(localStorage.getItem("user")).email;
      this.email.setValue(JSON.parse(localStorage.getItem("user")).email);
      this.name .setValue(JSON.parse(localStorage.getItem("user")).name);
    } 
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }

  ngAfterViewInit(): void {
    this.hideIntercomIcon();
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
  }

  prev(){
    this.emailAvailable =false;
  }

  goToLifeStyle() {
    if (this.email.valid) {
      this.isLoading = true;


      // if( this.defaultEmail === this.email.value){
      //   let obj = {
      //     email: this.email.value,
      //     name:this.name.value
      //   };
      //   this.api.saveQuizMail(obj).subscribe((res: any) => {
      //     if (res.user && res.user.first_name === "") {
      //       res.user.first_name = res.user.email.split("@")[0];
      //       res.user.name = this.name.value
      //         ? this.name.value
      //         : res.user.email.split("@")[0];
      //     }
      //     this.saveDesignBookings();
      //     this.emailAvailable = false;
      //     this.api.updateQuizUser(this.quizId).subscribe((res: any) => {
      //     });
      //   });
      // } else {
        this.api.getUserByMail(this.email.value).subscribe((res: any) => {
          if (res.status) {
            this.emailAvailable = true;
            this.isLoading = false;
  
          } else {
            let obj = {
              email: this.email.value,
              name:this.name.value
            };
  
            this.api.saveQuizMail(obj).subscribe((res: any) => {
              if (res.user && res.user.first_name === "") {
                res.user.first_name = res.user.email.split("@")[0];
                res.user.name = this.name.value
                  ? this.name.value
                  : res.user.email.split("@")[0];
              }
            this.isLoading = false;
              if(res.user){              this.updateUser(res);
              this.emailAvailable = false;
              this.api.updateQuizUser(this.quizId).subscribe((res: any) => {
              });
            } else {
              this.errorMsg = res.status;
            }
         
          },err=>{   this.isLoading = false;});
          } 
        });
      // }

   
    }
    // this.currentPage = 10;
  }
  reset(){
    this.router.navigate([]).then(() => {
      window.open(`/forgot-password`);
    });
  }
  signIn() {
    this.isLoading = true;
    this.errorMsg = "";
    let obj = {
      email: this.email.value,
      password: this.password.value,
      name: this.name.value,
    };
    this.api.login(obj).subscribe(
      (res: any) => {
        if (res.error) {
          this.errorMsg = res.error;
          this.isLoading = false;
          return false;
        }

        console.log(res);
        this.updateUser(res);
        this.api
          .saveQuizMail({ email: this.email.value,  name: this.name.value})
          .subscribe((res: any) => {
            this.api.updateQuizUser(this.quizId).subscribe((res: any) => {
            });
          });
      },
      (err) => {
        if (err.error) {
          this.errorMsg = err.error.error;
          this.isLoading = false;
          return false;
        }
      }
    );
  }
  expiredDate = new Date();
  updateUser(payload) {
    this.showAuth = false;
    this.expiredDate.setMonth(this.expiredDate.getMonth() + 6);
    this.cookie.set("token", `${payload.success.token}`, this.expiredDate, "/");
    localStorage.setItem("user", JSON.stringify(payload.user));
    this.eventService.fetchUser(payload.success.token, payload.user);
    this.eventService.invokeUserChange(payload.user);
    this.saveDesignBookings();
    setTimeout(() => {
      this.showAuth = true;
    }, 1000);
  }

  saveDesignBookings() {
    this.selectedDept = this.api.quizCategory;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {

let firstName: string = '';
let lastName: string = '';
      if(this.name.value){
        const firstSpaceIndex = this.name.value.indexOf(' ');

// Split into first name and last name

if (firstSpaceIndex !== -1) { // If a space exists
  firstName = this.name.value.slice(0, firstSpaceIndex); // Before first space
  lastName = this.name.value.slice(firstSpaceIndex + 1); // After first space
} else {
  firstName = this.name.value; // No space, entire input is the first name
  lastName = ''; // No last name
}
      }
      let data = {
        email: user.email,
        first_name: this.name.value ? firstName : user.first_name,
        last_name: this.name.value? lastName : user.last_name,
        phone: "",
        room: this.selectedDept,
        zipcode: "",
        quiz_id: this.quizId,
      };
      this.api.saveDesignBookings(data).subscribe((res: any) => {
        if (res && res.status) {
          this.isLoading =false;
          this.roomId = res.room_id;
          this.api.quizId = this.quizId;
          this.router.navigate([
            `/design/book/project-details/${this.roomId}`,
          ]);
        } else {
          this.isLoading =false;
        }
      }, err=>{
        this.isLoading =false;

      });
    }
  }

  Back() {
    this.router.navigate([`/style-quiz/my-style/${this.quizId}`]);
  }

  get deviceHeight() {
    return window.innerHeight;
  }
}
