import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";
import Chart from "chart.js";
import { FormControl, Validators } from "@angular/forms";
import { EnvService } from "src/app/shared/services/env.service";
import { CookieService } from "ngx-cookie-service";
import { ChatService } from "src/app/shared/services/api/chat.service";
import { delay } from "rxjs/operators";

@Component({
  selector: 'app-chat-genomes-mobile',
  templateUrl: './chat-genomes-mobile.component.html',
  styleUrls: ['./chat-genomes-mobile.component.less']
})
export class ChatGenomesMobileComponent implements OnInit {
  @Input() styleData: any;
 firstSelectedImages = [];
 secondSelectedImages = [];
 thirdSelectedImages = [];
 genomesList: any[] = [];
 shownImages = [];
 type: string; 

 page3Col1List: any[] = [];
 page3Col2List: any[] = [];

 page4Col1List: any[] = [];
 page4Col2List: any[] = [];

 page5Col1List: any[] = [];
 page5Col2List: any[] = [];

 deviceHeight: number;
 alreadyShownImages: any[] = [];
 isNextClicked = false;
 limitReached = false;
 noOfTimesNextClicked: number = 0;
 randomImageWithoutType: any;
 deviceWidth: number;
 @Input() quizId: string = "";
 page1: any[] = [];
 page2: any[] = [];
 currentPage = 1;
 genomeName: string;
 genomeDescription: string;
 genomeImage: string;
 subStylesList: any[] = [];
 @ViewChild("chart", { static: false })
 private chartRef: ElementRef;
 private chart: Chart;
 currentSetOption: number = 1;
 showLoadMore: boolean;
 bottomScrolled: boolean;
 selectedDept: string;
 selectedCategory: string;
 quizCategories: any[] = [];
 quizDepartments: any[] = [];
 prd1List: any[] = [];
 prd2List: any[] = [];
 prd3List: any[] = [];
 selectedProductImages: any[] = [];
 selectedProducts: any[] = [];
 
 isPage5Skipped: boolean;
 isPage4Skipped: boolean; 
 
 quizCategory: any;
 roomId: any;
 onInitLoaded: boolean;

 @Output() styleReport = new EventEmitter();
 @Output() msgFromGenome = new EventEmitter();
 @Output() showLoader = new EventEmitter();

 @Input() conversation_id: string = "";
  userId: any;
  showStyleGroup: boolean;
  genomeData: any[]=[];

 constructor(
   private api: ApiService,
   private chatService: ChatService,
   private loc: Location
 ) {
   
 }
 

 get isLoggedIn(): boolean {
   return (
     JSON.parse(localStorage.getItem("user")) &&
     JSON.parse(localStorage.getItem("user")).email.length > 0
   );
 }


 loadGenomes(){
  this.showStyleGroup = false;
  let randomNm = [150, 200, 250, 300]
  for(let i=0;i<=5;i++){

    this.page3Col1List.push({link:'',id:0,checked:false, height: 
      randomNm[(Math.floor(Math.random() * randomNm.length))]+'px' 
    });
    this.page3Col2List.push({link:'',id:0,checked:false, height: 
      randomNm[(Math.floor(Math.random() * randomNm.length))]+'px' });
  }
  if (this.selectedDept) {
   this.currentPage = 3;
    
  if(this.chatService.shuffledImages && this.chatService.shuffledImages.length>0){
    this.formImgColumns(this.chatService.shuffledImages);
  } else {
  this.getGenomeData();
  }
}
 }

 getGenomeData() {
  this.api.getGenomeData().subscribe((res: any) => {
    this.genomesList = res.genomes;

    this.shownImages = this.getRandomImagesFromGenomes();
    this.chatService.shuffledImages = this.shuffleArray(this.shownImages);
    let randomArr = JSON.parse(JSON.stringify( this.chatService.shuffledImages))
    this.formImgColumns(randomArr);
  });
}
 ngOnInit() {
  // this.chatService.promptCategory = null;
  this.chatService.bottomScrolled.subscribe(resp=>{
    this.bottomScrolled = resp
  })
  document.body.style.setProperty("background-color", "white", "important");

  this.userId = JSON.parse(localStorage.getItem("user")).id;

  this.selectedDept = this.chatService.quizCategory;
  if(this.styleData.genome_ids && this.styleData.genome_ids.length>0){
    this.showStyleGroup = true; 
   }
   if(this.showStyleGroup){
    this.genomeData =  this.styleData.genome_ids;
   } else {
    this.loadGenomes();
   }

  this.hideIntercomIcon();
  this.deviceHeight = window.innerHeight;
  this.deviceWidth = window.innerWidth;
  this.onInitLoaded = true;
}

 updateBookingTask() {
   const data = {
     room_id: this.roomId,
     tasks_style: "1",
     style_quiz_id: this.quizId,
   };
   this.api.updateBookingTask(data).subscribe((res) => {});
 }

 hideIntercomIcon() {
   const interval = setInterval(() => {
     const intercom = document.getElementsByClassName(
       "intercom-lightweight-app"
     )[0];
     if (intercom) {
       intercom.classList.add("dn");
       clearInterval(interval);
     }
   }, 500);
 }
 getQuizCategories() {
   this.api.getQuizCategories().subscribe((res: any) => {
     this.quizDepartments = res;
     if (this.selectedDept) {
       let i = this.quizDepartments.findIndex(
         (item) =>
           item.room.toLowerCase() === this.selectedDept.toLowerCase() ||
           item.dept_name.toLowerCase() === this.selectedDept.toLowerCase()
       );
       this.quizCategories = this.quizDepartments[i].categories;
       if (i >= 0 && !this.loc.path().includes("/design/rooms")) {
         this.currentPage = 2;
       }
     }
   });
 }
 
 loadMore() {
   this.currentSetOption = this.currentSetOption + 1;
   this.shownImages = this.getRandomImagesFromGenomes();
   // this.selectedImages = [];
   if (this.shownImages.length > 0) {
     let randomArr = this.shuffleArray(this.shownImages);
     const chunkSize = Math.ceil(randomArr.length / 3);

     let col1 = randomArr.slice(0, chunkSize);
     let col2 = randomArr.slice(chunkSize, 2 * chunkSize);
     let col3 = randomArr.slice(2 * chunkSize);

     if (this.currentPage === 3) {
       let a1 = this.page3Col1List.concat(...col1);
       let a2 = this.page3Col2List.concat(...col2);

       this.page3Col1List = a1;
       this.page3Col2List = a2;
     } else if (this.currentPage === 4) {
       let a1 = this.page4Col1List.concat(...col1);
       let a2 = this.page4Col2List.concat(...col2);

       this.page4Col1List = a1;
       this.page4Col2List = a2;
     } else if (this.currentPage === 5) {
       let a1 = this.page5Col1List.concat(...col1);
       let a2 = this.page5Col2List.concat(...col2);

       this.page5Col1List = a1;
       this.page5Col2List = a2;
     }

     this.saveQuizViewedGenomes(this.currentPage === 1 ? "A" : "B");
     if (this.currentSetOption === 3) {
       this.showLoadMore = false;
     }
   }
 }
 createStyleQuiz() {
   let obj = {
     department: this.selectedDept,
     category: this.selectedCategory,
   };
   this.api.createStyleQuiz(obj).subscribe((res: any) => {
     this.quizId = res.quiz_id;
   });
 }
 selectImage(i, col) {
   let selectionLimit = this.currentPage === 5 ? 5 : 3;
   let firstCol =
     this.currentPage === 3
       ? this.page3Col1List
       : this.currentPage === 4
       ? this.page4Col1List
       : this.page5Col1List;
   let secondCol =
     this.currentPage === 3
       ? this.page3Col2List
       : this.currentPage === 4
       ? this.page4Col2List
       : this.page5Col2List;

   let selectedImagesPage =
     this.currentPage === 3
       ? this.firstSelectedImages
       : this.currentPage === 4
       ? this.secondSelectedImages
       : this.thirdSelectedImages;
   let name =
     this.currentPage === 3
       ? "firstSelectedImages"
       : this.currentPage === 4
       ? "secondSelectedImages"
       : "thirdSelectedImages";

   if (col === 1) {
     if (!firstCol[i].checked && selectedImagesPage.length < selectionLimit) {
       firstCol[i].checked = true;
     } else {
       firstCol[i].checked = false;
     }
     if (firstCol[i].checked) {
       selectedImagesPage.push(firstCol[i]);
     } else {
       selectedImagesPage = selectedImagesPage.filter(
         (item) => item.id !== firstCol[i].id
       );
       this[name] = selectedImagesPage;
     }
   } else if (col === 2) {
     if (!secondCol[i].checked && selectedImagesPage.length < selectionLimit) {
       secondCol[i].checked = true;
     } else {
       secondCol[i].checked = false;
     }

     if (secondCol[i].checked) {
       selectedImagesPage.push(secondCol[i]);
     } else {
       selectedImagesPage = selectedImagesPage.filter(
         (item) => item.id !== secondCol[i].id
       );

       this[name] = selectedImagesPage;
     }
   }
 }
 refreshImages() {
  this.bottomScrolled = false;
   let obj = {
     quiz_id: "",
     selection: [],

    is_conversation : true,
    conversation_id:this.conversation_id
   };
   let set =
     this.currentPage === 3 ? "A" : this.currentPage === 4 ? "B" : "final";
   obj["quiz_id"] = this.quizId;
   obj["selection"] = [];
   

   let selImages =
     this.currentPage === 3
       ? this.firstSelectedImages
       : this.currentPage === 4
       ? this.secondSelectedImages
       : this.thirdSelectedImages;
   obj["selection"].push({
     selection_set: set,
     images: selImages.map((item) => item.id).join(","),
   });
   // write a code for refreh random images that should not show the aleady shown images
   this.isNextClicked = true;
   this.noOfTimesNextClicked++;
   if (this.noOfTimesNextClicked === 1) {
     this.page1 = this.firstSelectedImages;
   } else if (this.noOfTimesNextClicked === 2) {
     this.page2 = this.secondSelectedImages;
   }

   if (this.currentPage === 5) {
     this.currentPage = 7;
   }
   if (this.currentPage === 4) {
     this.currentPage = 5;
   }
   if (this.currentPage < 5) {
     if (this.page1.length === 1) {
       this.currentPage = 4;
       this.currentSetOption = 1;
     } else {
       this.currentPage = 5;
       this.isPage4Skipped = true;
     }

     if (
       this.page1.length === 1 &&
       this.page2.length === 1 &&
       this.page1[0].genomeIndex === this.page2[0].genomeIndex
     ) {
       this.getStyleReport();
       this.isPage5Skipped = true;
     }
     if (
       this.page1.length === 1 &&
       this.page2.length === 1 &&
       this.page1[0].genomeIndex !== this.page2[0].genomeIndex
     ) {
       this.currentPage = 5;
       this.isPage4Skipped = true;
     }
   }

   this.saveQuizSelectedGenomes(obj);
 }

 getStyleReport() {
  this.chatService.getStyleReportByConvId(this.conversation_id).subscribe(
    (res: any) => {
      if (res && res.genome && res.genome.length > 0) {
        this.styleReport.emit(res);
        // if (this.loc.path().includes("/design/project")) {
        //   if (!this.loc.path().includes("/style-report")) {
        //     this.router.navigate([
        //       `design/projects/${this.roomId}/style-report`,
        //     ]);
        //   }
        // } else {
        //   this.router.navigate([
        //     `style-quiz/my-style/${res.quiz_id}`,
        //   ]);
        // }
        this.genomeName = res.genome[0].genome_name;
        this.genomeDescription = res.genome[0].genome_description;
        this.genomeImage = res.genome[0].genome_image;
        this.subStylesList = res.substyles;

        // setTimeout(() => {
        //   this.loadChart();
        // }, 1000);
      }
    },
    () => {}
  );
}

 getRandomImagesFromGenomes() {
   const randomImages = [];

   if (this.currentPage === 5) {
     const pageLength = this.page1.length;
     let count = 3;
     if (pageLength === 1) {
       count = 24;
       for (let i = 0; i < 24; i++) {
         let index = this.page1[0].genomeIndex;
         const randomBedroomImage = this.getRandomBedroomImage(
           this.genomesList[index],
           index,
           this.genomesList[index].genome_value
         );
         if (randomBedroomImage) {
           randomImages.push({ ...randomBedroomImage, checked: false });
         }
       }
     } else if (pageLength === 2) {
       count = 12;
       for (let i = 0; i < this.page1.length; i++) {
         for (let j = 0; j < 12; j++) {
           let index = this.page1[i].genomeIndex;
           const randomBedroomImage = this.getRandomBedroomImage(
             this.genomesList[index],
             index,
             this.genomesList[index].genome_value
           );
           if (randomBedroomImage) {
             randomImages.push({ ...randomBedroomImage, checked: false });
           }
         }
       }
     } else if (pageLength === 3) {
       count = 8;
       for (let i = 0; i < this.page1.length; i++) {
         for (let j = 0; j < 8; j++) {
           let index = this.page1[i].genomeIndex;
           const randomBedroomImage = this.getRandomBedroomImage(
             this.genomesList[index],
             index,
             this.genomesList[index].genome_value
           );
           if (randomBedroomImage) {
             randomImages.push({ ...randomBedroomImage, checked: false });
           }
         }
       }
     }
     return randomImages;
   } else {
     this.genomesList.forEach((genome, i) => {
       const randomBedroomImage = this.getRandomBedroomImage(
         genome,
         i,
         genome.genome_value
       );
       if (randomBedroomImage) {
         randomImages.push({ ...randomBedroomImage, checked: false });
       }
     });
     return randomImages;
   }
 }

 getRandomBedroomImage(genome, i, genomeName) {
   console.log(this.selectedDept, "type");
   if (this.selectedDept) {
     const bedroomGallery = genome.gallery.find(
       (room) => room.room.toLowerCase() === this.selectedDept.toLowerCase()
     );
     if (bedroomGallery && bedroomGallery.gallery.length > 0) {
       return this.getRandomImg(bedroomGallery.gallery, i, genomeName);
     } else {
       return this.getRandomLivingImage(genome, i, genomeName);
     }
   } else {
     let allImages = [];
     allImages = genome.gallery.flatMap((room) => room.gallery);
     return this.getRandomImg(allImages, i, genomeName);
   }
 }
 getRandomLivingImage(genome, i, genomeName) {
   const bedroomGallery = genome.gallery.find(
     (room) => room.room.toLowerCase() === "living"
   );
   if (bedroomGallery && bedroomGallery.gallery.length > 0) {
     return this.getRandomImg(bedroomGallery.gallery, i, genomeName);
   }

   return null;
 }

 getRandomImg(allImages, i, genomeName) {
   this.randomImageWithoutType = null;
   if (allImages && allImages.length > 0) {
     let ran = Math.floor(Math.random() * allImages.length);
     if (allImages.some((item) => !item["alreadyShown"])) {
       if (allImages[ran] && allImages[ran]["alreadyShown"] === true) {
         this.getRandomImg(allImages, i, genomeName);
       } else {
         this.randomImageWithoutType = allImages[ran];
         this.randomImageWithoutType["alreadyShown"] = true;

         this.randomImageWithoutType["genome_value"] = genomeName;
         this.randomImageWithoutType["genomeIndex"] = i;
       }
     }
   }
   return this.randomImageWithoutType;
 }

 saveQuizViewedGenomes(set) {
  let obj = {
    quiz_id: "",
    selection: [],
  };
  obj["quiz_id"] = this.quizId;
  obj["selection"] = [];
  obj["selection"].push({
    selection_set: set,
    selection_set_option: this.currentSetOption,
    images: this.shownImages.map((item) => item.id).join(","),
  });
  this.api.saveQuizViewedGenomes(obj).subscribe((res: any) => {});
}
 saveQuizSelectedGenomes(obj) {
  if (obj.selection[0].selection_set === "final") {
    this.showLoader.emit(true);
  }
  this.api.saveQuizSelectedGenomes(obj).subscribe((resMsg: any) => {
    if (obj.selection[0].selection_set === "final") {
      this.chatService.getStyleReportByConvId(this.conversation_id).subscribe(
        (res: any) => { 
          if (res && res.genome && res.genome.length > 0) {
            this.styleReport.emit(res);
            this.genomeName = res.genome[0].genome_name;
            this.genomeDescription = res.genome[0].genome_description;
            this.genomeImage = res.genome[0].genome_image;
            this.subStylesList = res.substyles;
            this.chatService.plpPageNo = -1;
            setTimeout(() => {
             //  this.loadChart();
            }, 1000);
          }
          this.msgFromGenome.emit(resMsg);
        },
        () => {}
      );
    
     }
    if (this.currentPage === 7) {
      this.getStyleReport();
    } else {
      this.goNext();
    }
  });
}
callQuizSelectedGenomes(data) {
  this.showLoader.emit(true);
  let obj ={"quiz_id":"",
    "selection":"",
    "genome_id":data.genome_id,
    "is_conversation":true,
    "conversation_id":this.conversation_id}
  this.api.saveQuizSelectedGenomes(obj).subscribe((res: any) => {
    // if (obj.selection[0].selection_set === "final") {
      this.msgFromGenome.emit(res);
    // }
    // this.getStyleReport();
    // if (this.currentPage === 7) {
    // } else {
    //   this.goNext();
    // }
  });
}
 goNext() {
   this.shownImages = this.getRandomImagesFromGenomes();
   // this.selectedImages = [];
   if (this.shownImages.length > 0) {
     let randomArr = this.shuffleArray(this.shownImages);
     // this.col1List = [];
     // this.col2List = [];
     // this.col3List = [];
     let firstCol =
       this.currentPage === 3
         ? this.page3Col1List
         : this.currentPage === 4
         ? this.page4Col1List
         : this.page5Col1List;
     let secondCol =
       this.currentPage === 3
         ? this.page3Col2List
         : this.currentPage === 4
         ? this.page4Col2List
         : this.page5Col2List;

     for (let i = 0; i < randomArr.length; i++) {
       if (i % 2 == 0) {
         firstCol.push(randomArr[i]);
       } else {
         secondCol.push(randomArr[i]);
       }
     }
   } else {
     this.limitReached = true;
   }
   if (this.currentPage === 4) {
     this.saveQuizViewedGenomes("B");
   }
 }
 shuffleArray<T>(array: T[]): T[] {
   // Fisher-Yates shuffle algorithm
   for (let i = array.length - 1; i > 0; i--) {
     const j = Math.floor(Math.random() * (i + 1));
     [array[i], array[j]] = [array[j], array[i]];
   }
   return array;
 }

 selectCard(name, i) {
   if (this.currentPage === 2) {
     this.selectedCategory = name;
     this.currentPage = 3;
     this.createStyleQuiz();
     this.getGenomeData();
   }
   if (this.currentPage === 1) {
     this.selectedDept = name;
     this.quizCategories = this.quizDepartments[i].categories;
     this.currentPage = 2;
   }
   window.scrollTo(0, 0);
 }

 formImgColumns(randomArr){
  this.page3Col1List = [];
  this.page3Col2List = [];
  this.page4Col1List = [];
  this.page4Col2List = [];
  this.page5Col1List = [];
  this.page5Col2List = [];
  this.firstSelectedImages = [];
  this.secondSelectedImages = [];
  this.thirdSelectedImages = [];

  let firstCol =
    this.currentPage === 3
      ? this.page3Col1List
      : this.currentPage === 4
      ? this.page4Col1List
      : this.page5Col1List;
  let secondCol =
    this.currentPage === 3
      ? this.page3Col2List
      : this.currentPage === 4
      ? this.page4Col2List
      : this.page5Col2List;

  for (let i = 0; i < randomArr.length; i++) {
    if (i % 2 == 0) {
      firstCol.push(randomArr[i]);
    } else {
      secondCol.push(randomArr[i]);
    }
  }
  this.saveQuizViewedGenomes("A");
  this.showLoadMore = true;
 }

 selectProductImage(i, col) {
   let selectionLimit = 5;
   if (col === 1) {
     if (
       !this.prd1List[i].checked &&
       this.selectedProductImages.length < selectionLimit
     ) {
       this.prd1List[i].checked = true;
     } else {
       this.prd1List[i].checked = false;
     }
     if (this.prd1List[i].checked) {
       this.selectedProductImages.push(this.prd1List[i]);
     } else {
       this.selectedProductImages = this.selectedProductImages.filter(
         (item) => item.product_sku !== this.prd1List[i].product_sku
       );
     }
   } else if (col === 2) {
     if (
       !this.prd2List[i].checked &&
       this.selectedProductImages.length < selectionLimit
     ) {
       this.prd2List[i].checked = true;
     } else {
       this.prd2List[i].checked = false;
     }

     if (this.prd2List[i].checked) {
       this.selectedProductImages.push(this.prd2List[i]);
     } else {
       this.selectedProductImages = this.selectedProductImages.filter(
         (item) => item.product_sku !== this.prd2List[i].product_sku
       );
     }
   } else if (col === 3) {
     if (
       !this.prd3List[i].checked &&
       this.selectedProductImages.length < selectionLimit
     ) {
       this.prd3List[i].checked = true;
     } else {
       this.prd3List[i].checked = false;
     }

     if (this.prd3List[i].checked) {
       this.selectedProductImages.push(this.prd3List[i]);
     } else {
       this.selectedProductImages = this.selectedProductImages.filter(
         (item) => item.product_sku !== this.prd3List[i].product_sku
       );
     }
   }
 }
 
 Back() {
   if (this.currentPage === 4) {
     this.noOfTimesNextClicked = 1;
   }
   if (this.currentPage === 5) {
     this.noOfTimesNextClicked = 2;
     if (this.isPage4Skipped) {
       this.currentPage = 3;
       this.isPage4Skipped = false;
       this.noOfTimesNextClicked = 0;
     } else {
       this.currentPage = this.currentPage - 1;
     }
   } else if (this.currentPage === 9) {
     if (this.isLoggedIn) {
       this.currentPage = 7;
     } else {
       this.currentPage = 8;
     }
   } else {
     this.currentPage = this.currentPage - 1;
   }
   window.scrollTo(0, 0);
 }
 get firstCol() {
   return this.currentPage === 3
     ? this.page3Col1List
     : this.currentPage === 4
     ? this.page4Col1List
     : this.page5Col1List;
 }
 get secondCol() {
   return this.currentPage === 3
     ? this.page3Col2List
     : this.currentPage === 4
     ? this.page4Col2List
     : this.page5Col2List;
 }
 get getSelectedImgesLength() {
   return this.currentPage === 3
     ? this.firstSelectedImages.length
     : this.currentPage === 4
     ? this.secondSelectedImages.length
     : this.thirdSelectedImages.length;
 }
 
}