import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-variations-new",
  templateUrl: "./variations-new.component.html",
  styleUrls: ["./variations-new.component.less"],
})
export class VariationsNewComponent implements OnInit {
  @Input() varData: any;
  @Output() priceData = new EventEmitter<any>();
  @Output() selectedData = new EventEmitter<any>();
  @Output() selectionClear = new EventEmitter<any>();
  @Output() setImage = new EventEmitter<any>();

  variationData: any[];
  priceDetails: any[];
  filteredVariationData: any[];
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset: boolean;
  isSkudetailsAvailable: boolean;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }
  decideWhichToChoose() {
    this.isSkudetailsAvailable =
      this.varData.Skudetails.length > 0 ? true : false;
    if (this.varData.Skudetails.length === 1) {
      this.priceData.emit({
        sellingPrice: this.varData.Skudetails[0].price,
        retailPrice: this.varData.Skudetails[0].was_price,
      });
      this.selectedData.emit(this.varData.Skudetails[0].sku);
      this.setImage.emit(this.varData.Skudetails[0]);

    }
    // this.variationData = this.varData.Skudetails;
    // this.emitPriceData()
  }
  
  onBoxScroll(event: WheelEvent) {
    const box = event.currentTarget as HTMLElement;
    const isAtTop = box.scrollTop === 0;
    const isAtBottom = box.scrollTop + box.clientHeight >= box.scrollHeight;

    if ((!isAtTop && event.deltaY < 0) || (!isAtBottom && event.deltaY > 0)) {
      // ✅ Allow box to scroll independently
      event.stopPropagation();
    }
  }

  ngOnInit() {
    this.variationData = this.varData.selection;
    this.decideWhichToChoose();
    this.priceDetails = this.varData.Pricedetails;
    this.variationData.forEach((f, i: number) => {
      f.att_selected = "";
      f.choice_id = 0;
      f.sortList = [];
      f.availGroupData = [];
      f.colorGroupData = [];
      f.priceGroupData = [];
      f.sortSelected = "";
      f.disabled = false;
      if (f.options.length == 1 && f.requiredSelection == 0) {
        f.options.unshift({
          attributeName: "None",
          choice_id: 0,
          priceGroup: null,
          availabilityGroup: null,
          colorGroup: null,
          eligibility: "",
          availabilityGroupLabel: null,
          colorGroupLabel: null,
          selected: false,
        });
      }
      if (f.groupby) {
        const data = f.groupby.split(",");
        data.forEach((e) => {
          f.sortList.push({
            label: e.split("_group")[0].toUpperCase(),
            selected: false,
          });
        });
      }

      if (f.sortList.length > 0) {
        const data = f.sortList.find((s) => s.label === "AVAILABILITY");
        if (data) {
          data.selected = true;
          this.groupBy(f, "AVAILABILITY", i);
        } else {
          f.sortList[0].selected = true;
          this.groupBy(f, f.sortList[0], i);
        }
      }
      f.item_selected = null;
      f.options.forEach((e) => {
        e.selected = false;
      });
    });
    this.filteredVariationData = JSON.parse(JSON.stringify(this.variationData));
  }
  selectText(data: any, ind: number, parentIndex: number) {
    data.options.forEach((f, index) => {
      if (ind === index) {
        f.selected = true;
        data.att_selected = f.attributeName;
        data.choice_id = f.choice_id;
        data.item_selected = f;
      } else {
        f.selected = false;
      }
    });
    this.filterSelections(data, parentIndex);
  }
  selectedVariation(
    data: any,
    parentIndex: number,
    ind: number,
    groupArr: any[],
    type: string
  ) {
    if (type === "no-filter") {
      this.selectText(data, ind, parentIndex);
    } else {
      groupArr.forEach((f, index) => {
        if (ind === index) {
          f.selected = true;
          data.att_selected = f.attributeName;
          data.choice_id = f.choice_id;
          data.item_selected = f;
        } else {
          f.selected = false;
        }
      });

      this.filterSelections(data, parentIndex);
      this.setImage.emit(data);

    }
  }
  filterSelections(data: any, parentIndex: number) {
    const newAssginData = this.variationData[parentIndex + 1];
    if (newAssginData && newAssginData.dependence) { 
      newAssginData.options = this.filteredVariationData[
        parentIndex + 1
      ].options.filter((f) =>
        f.eligibility.includes("" + data.item_selected.choice_id)
      );
      // }
      newAssginData.disabled = false;
      if (
        newAssginData.options.length === 0 &&
        this.filteredVariationData[parentIndex + 1].options.length === 2
      ) {
        newAssginData.options =
          this.filteredVariationData[parentIndex + 1].options;
        newAssginData.disabled = true;
        newAssginData.options[0].selected = true;
        newAssginData.options[1].selected = false;
      }

      if (
        newAssginData.requiredSelection == 0 &&
        newAssginData.options.length == 1
      ) {
        newAssginData.options.unshift({
          attributeName: "None",
          choice_id: 0,
          priceGroup: null,
          availabilityGroup: null,
          colorGroup: null,
          eligibility: "",
          availabilityGroupLabel: null,
          colorGroupLabel: null,
          selected: false,
        });
      }

      this.groupBy(newAssginData, newAssginData.sortSelected, parentIndex + 1);
    }
    this.emitPriceData();
  }
  groupBy(data: any, key: string, index: number) {
    data.sortList.forEach((f) => {
      if (f.label === key) {
        f.selected = true;
        data.sortSelected = key;
      } else {
        f.selected = false;
      }
    });
    if (key === "PRICE") {
      let obj = this.variationData[index].options.reduce((r, a) => {
        r[a.priceGroup] = r[a.priceGroup] || [];
        r[a.priceGroup].push(a);
        return r;
      }, Object.create(null));
      data.priceGroupData = Object.values(obj);
    } else if (key === "COLOR") {
      let obj = this.variationData[index].options.reduce((r, a) => {
        r[a.colorGroup] = r[a.colorGroup] || [];
        r[a.colorGroup].push(a);
        return r;
      }, Object.create(null));
      data.colorGroupData = Object.values(obj);
    } else if (key === "AVAILABILITY") {
      let obj = this.variationData[index].options.reduce((r, a) => {
        r[a.availabilityGroup] = r[a.availabilityGroup] || [];
        r[a.availabilityGroup].push(a);
        return r;
      }, Object.create(null));
      data.availGroupData = Object.values(obj);
    }
  }
  emitPriceData() {
    let priceGrp = [];
    let varData = [];
    let finalPriceGrp = [];
    let sellingPrice = 0;
    let retailPrice = 0;
    this.variationData.forEach((f) => {
      if (f.item_selected && f.disabled) {
        f.item_selected = null;
        f.att_selected = null;
        f.choice_id = 0;
      }
      f.item_selected && f.options.length > 0
        ? priceGrp.push(f.item_selected.priceGroup)
        : null;
      f.item_selected && f.options.length > 0
        ? varData.push(f.item_selected.choice_id)
        : null;
    });
    priceGrp = priceGrp.filter((f) => f !== null);
    const [first, ...rest] = priceGrp;
    if (priceGrp.length > 1) {
      rest.forEach((elem) => {
        finalPriceGrp.push(`${first},${elem}`);
      });
    } else {
      finalPriceGrp = priceGrp;
    }

    finalPriceGrp.forEach((f) => {
      let index = this.priceDetails
        .map((m) => m.priceGroup)
        .findIndex((n) => n === f);
      if (index > -1) {
        sellingPrice = sellingPrice + +this.priceDetails[index].sellingPrice;
        retailPrice = retailPrice + +this.priceDetails[index].retailPrice;
      }
    });
    if (sellingPrice === 0) {
      this.priceDetails.forEach((f: any, i: number) => {
        if (this.compareArrays(priceGrp, f.priceGroup.split(","))) {
          sellingPrice = sellingPrice + +this.priceDetails[i].sellingPrice;
          retailPrice = retailPrice + +this.priceDetails[i].retailPrice;
        }
      });
    }
    if (sellingPrice === 0) {
      priceGrp.forEach((f) => {
        let index = this.priceDetails
          .map((m) => m.priceGroup)
          .findIndex((n) => n === f);
        if (index > -1) {
          sellingPrice = sellingPrice + +this.priceDetails[index].sellingPrice;
          retailPrice = retailPrice + +this.priceDetails[index].retailPrice;
        }
      });
    }
    if (this.isSkudetailsAvailable) {
      const newVData = varData.filter((f) => f !== 0).join(",");
      let findSku = this.varData.Skudetails.filter(
        (f) =>
          f.selectionChoiceIds === newVData ||
          this.compareArrays(
            newVData.split(","),
            f.selectionChoiceIds ? f.selectionChoiceIds.split(",") : []
          )
      );
      findSku = findSku.filter((f) => f.is_active==='1');
      if (findSku.length > 0) {
        this.priceData.emit({
          sellingPrice: findSku[0].price,
          retailPrice: findSku[0].was_price,
        });
        this.selectedData.emit(findSku[0].sku);
      this.setImage.emit(findSku[0]);

      } else {
        if (this.varData.selection.length === this.variationData.filter(f=>f.item_selected && f.item_selected.attributeName).length) {
          this.priceData.emit({
            sellingPrice: 0,
            retailPrice: 0,
          });
          this.selectedData.emit(undefined);
      this.setImage.emit([]);

        }
      }
    } else {
      if (this.varData.selection.length === this.variationData.filter(f=>f.item_selected && f.item_selected.attributeName).length) {
        this.priceData.emit({
          sellingPrice: sellingPrice,
          retailPrice: retailPrice,
        });
      this.setImage.emit([]);

        this.selectedData.emit(varData.filter((f) => f !== 0).join("-"));
      }  
    }
  }
  compareArrays(arr1: string[], arr2: string[]): boolean {
    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();
    if (sortedArr1.length !== sortedArr2.length) {
      return false;
    }
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }
  clearSelections() {
    this.variationData.forEach((f) => {
      f.item_selected = null;
      f.att_selected = null;
      f.choice_id = 0;
      f.options.forEach((m) => {
        m.selected = false;
      });
    });
    this.emitPriceData();
    this.selectionClear.emit(true);
    this.setImage.emit('');

  }
}
