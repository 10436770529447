import { DatePipe, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-design-proposals',
  templateUrl: './design-proposals.component.html',
  styleUrls: ['./design-proposals.component.less'],
  providers: [DatePipe]
})
export class DesignProposalsComponent implements OnInit {
  @Input() isHandset: boolean;
   @Input() currentBoard: string;
  @Input() currentVersionList: any[] = [];
  @Output() detailViewEmit = new EventEmitter(false);


  @Input() projectImages: any[] = [];
  fileList: any[] = [];
  apiCount: number;
  roomId: any;
  loading: boolean;
  imageList: any[] = [];
  currentTab = 'Idea Board'; 

  designImages: any[] = [];
  conceptImages: any[] = [];

  imageResponse: any[] = [];

  ideaTitle: string = '';
  ideaNotes: any;
  ideaFileList: any[] = [];
  @ViewChild('confirmation') confirmation: TemplateRef<any>;
  currentItem: any;
  conceptFileList: any[] = [];
  designFileList: any[] = [];
  selectAll = false;

  versionList = ['Version 1', 'Version 2']
  currentIndex: number = 0;
  ideaVersionList: any[]=[];
  isVersionDisabled = false

  constructor(private api: ApiService, private snackbar: MatSnackBar, private actRou: ActivatedRoute,
    private dialog: MatDialog, private datePipe: DatePipe,private location:Location,
     router: Router) {
      // router.events.forEach((event) => {
      //   if(event instanceof NavigationStart) {
      //   //   if (event.state && event.state.page === 'summary') {
      //   //     this.goBack();
      //   //   } else {
      //   //     this.showSummary = false;
      //   //   }
      //   // }
      // // });
     }

  nextTab() {
    this.currentIndex = this.currentIndex + 1;
  }
  prevTab() {
    this.currentIndex = this.currentIndex - 1;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.projectImages && changes.projectImages.currentValue) {
      this.projectImages = changes.projectImages.currentValue;
      console.log(this.currentVersionList,'PRojectImagesß')
    }
  }

  changeTab(t: string) {
    this.currentTab = t;
  }

  ngOnInit(): void {
    window.onpopstate = (event) => {
      if (event.state && event.state.page === 'summary') {
        this.detailViewEmit.emit(false);

      } else {
        this.detailViewEmit.emit(true);

      }
    };
    this.api.roomId = this.actRou.snapshot.params.id;

    this.location.replaceState(`/admin/dashboard/design/rooms/${this.api.roomId}/designs`);

    if(this.currentVersionList?.length===0){
      this.createNewVersion();
    }
  }

  uploadDoc(event: any) {
    let reader = new FileReader();
    if (event.target.files) {
      if(this.currentVersionList[this.currentIndex].deliverable_id===0){
        this.isVersionDisabled = false;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        let imgs: any[] = this.currentVersionList[this.currentIndex].images
        imgs.unshift({
          comment: '',
          file_id: '',
          link: '',
          imgUrl: event.target.files[i],
          title: ''
        });

        reader = new FileReader();

        reader.readAsDataURL(event.target.files[i]); // read file as data url

        reader.onload = (x: any) => {

          this.currentVersionList[this.currentIndex].imagesList
            .unshift({
              comment: '',
              file_id: '',
              link: x.target.result,
              imgUrl: event.target.files[i],
              title: '', checked: true
            });


          if (event.target.files.length === i + 1) {
            let type = this.currentBoard == 'Idea Boards' ? 'idea' : this.currentBoard == 'Concept Boards' ?
              'concept' : 'design'
            this.addImage(type, this.currentVersionList[this.currentIndex].imagesList);
          }
        };
      }
    }
  }

  addImage(type: string, list: any[]) {
    let count = 0;
    this.apiCount = 0;
    if (list.length > 0) {
      count++;
    }
    if (list.length > 0) {
      let formData = new FormData();

      list.forEach((e, index) => {
        if(!e.file_id){
        formData.append("image" + (index + 1), e.imgUrl);
        }
      });
      formData.append("type", type);
      formData.append("count", "" + list.filter(f=>!f.file_id).length);

      this.save(formData, count);
    }

  }

  //savedesign_deliverables1
  save(formData, count) {
    formData.append("room_id", this.api.roomId);
    formData.append("version_date", this.currentVersionList[this.currentIndex].version_date)

    this.loading = true;
    this.api.saveDesignDeliverables(formData).subscribe(
      (res: any) => {
        if (res.status) {
          this.snackbar.open("Image Uploaded Successfully", "x", {
            duration: 3000,
          });
          this.getDesignImage();
        } else {
          this.loading = false;
          this.snackbar.open("Image Upload Failed, Please try again!", "x", {
            duration: 3000,
          });
        }
      },
      (err) => {
        this.loading = false;
        this.snackbar.open("Image Upload Failed, Please try again!", "x", {
          duration: 3000,
        });
      }
    );
  }

  getDesignImage() {
    this.api.getDesignProject(this.api.roomId).subscribe((resp: any) => {
      if (resp && resp.length > 0) {
        this.projectImages = resp;
        this.takeImages();
      }
    });
  }

  takeImages() {
    if (this.projectImages.length > 0) {
      const ideaVersions = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'idea')[0]?.versions;
      const conceptVersions = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'concept')[0]?.versions;
      const designVersions = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'design')[0]?.versions;

      let ideaVersionList = [];
      let conceptVersionList = [];
      let designVersionList = [];

      for (var v in ideaVersions) {
        ideaVersions[v].images.forEach(f => f.imgUrl = f.link)
        ideaVersionList.push({
          versionNo: v, checked: false, ...ideaVersions[v],
          imagesList: ideaVersions[v].images
        })
      }

      for (var v in conceptVersions) {
        conceptVersions[v].images.forEach(f => f.imgUrl = f.link)
        conceptVersionList.push({ versionNo: v, checked: false, ...conceptVersions[v], imagesList: conceptVersions[v].images })
      }
      for (var v in designVersions) {
        designVersions[v].images.forEach(f => f.imgUrl = f.link)
        designVersionList.push({ versionNo: v, checked: false, ...designVersions[v], imagesList: designVersions[v].images })
      } 

      if (this.currentBoard === 'Idea Boards') {
        this.currentVersionList = ideaVersionList;
      } else if (this.currentBoard === 'Concept Boards') {
        this.currentVersionList = conceptVersionList;
      } else {
        this.currentVersionList = designVersionList;
      }
    }
  }


  check(idea) {
    // if(!idea.is_published){
    idea.checked = true
    // }
  }

  openConfirmation() {
    this.dialog.open(this.confirmation, {
      disableClose: true,
      width: '34em'
    })
  }

  //update_deliverables
  updateDeliverables(callPublish = false) {

    let data = JSON.parse(JSON.stringify(this.currentVersionList[this.currentIndex]));
    ['versionNo', 'published_date', 'comment', 'checked', 'imagesList'].forEach(f => delete data[f]);
    data['room_id'] = this.api.roomId;
    // data['is_published'] = 1;
    data.images.forEach(f => {
      f.is_archived = ''+f.is_archived; 
      delete f.link;
      delete f.checked;
      delete f.imgUrl;


    })
    this.api.updateDesignDeliverables(data).subscribe((res: any) => {
      this.snackbar.open('Saved successfully', 'x', { duration: 1000 });
      if (callPublish) this.publishDeliverables(data);

    })

  }

  publishDeliverables(data) {
    let payload = {
      "room_id": data.room_id,
      "deliverable_id": data.deliverable_id
    }
    this.api.publisDesignDeliverables(payload).subscribe((res: any) => {
      if (res.status) {
        this.snackbar.open('Published successfully', 'x', { duration: 1000 });
        this.getDesignImage();
      }
    })
    this.dialog.closeAll();

  }

  saveAndPublish() {
    this.updateDeliverables(true)
  }

  close() {
    this.dialog.closeAll();
  }

  back() { 
    this.detailViewEmit.emit(false);
  }

  error() {
    return `<div class="skeleton-load skeleton-text"></div>`
  }

  createNewVersion() {
    this.currentVersionList.push(
      {
        "versionNo": this.datePipe.transform(new Date(), 'MMM-dd'),
        "checked": false,
        "version_date": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        "deliverable_id": 0,
        "is_published": 0,
        "published_date": null,
        "comment": null,
        "images": [],
        "imagesList":[]
      }
    );
    this.isVersionDisabled = true
    this.currentIndex = this.currentVersionList.length - 1;
  }
  deleteCard(idea) {
    idea['is_archived'] = 1;
    this.snackbar.open('Removed', 'x', { duration: 2000 })
  }

  get getSelections(): boolean {
    return true;
    // if (this.currentTab === 'Idea Board') {
    //   return this.ideaImages.some(f => f['checked']);
    // } else if (this.currentTab == 'Concept Boards') {
    //   return this.conceptImages.some(f => f['checked']);
    // } else {
    //   return this.designImages.some(f => f['checked']);

    // }
  }

}
