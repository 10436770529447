import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { ApiService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-compare",
  templateUrl: "./compare.component.html",
  styleUrls: ["./compare.component.less"],
})
export class CompareComponent implements OnInit {
 bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
     Breakpoints.Handset
   );
   @ViewChild("shop", { static: false }) shop: ElementRef<any>;
   @ViewChild("design", { static: false }) design: ElementRef<any>;
   @ViewChild("target", { static: false }) target: ElementRef<any>;
   ph = `Try "White Chair"`;
   bpSubscription: Subscription;
   isHandset = false;
   lsData = [
     { benefit: "Accredited Design Professionals", ls: 1, discount: 0 },
     { benefit: "Curated luxury desginer brands", ls: 1, discount: 0 },
     { benefit: "Realistic 3D models to scale", ls: 1, discount: 0 },
     { benefit: "Seamless integrated ordering ", ls: 1, discount: 0 },
     { benefit: "Discounted pricing on furniture", ls: 1, discount: 0 },
     { benefit: "Complimentary white glove delivery", ls: 1, discount: 0 },
   ];
   slideIndex = 1;
   profileList: any[] = [];
 
   isShop = true;
  
   public images = ["/assets/image/c1.jpg", "/assets/image/c2.jpg"];
categoryList = ['Work with qualified interior designers only',
  'Start your project within 3 business days',
  'Retail brand shopping discounts',
  'Completely independent - no owned brands',
  'Realtime phone & chat support'
]


   constructor(
     private breakpointObserver: BreakpointObserver,
     private apiService: ApiService,
     private router: Router,
     public env: EnvService
   ) { 
     this.bpSubscription = this.bpObserver.subscribe(
       (handset: BreakpointState) => {
         this.isHandset = handset.matches;
       }
     );
    this.apiService.onInitLoaded =false;

   }
   ngOnInit() {
     // this.getDesigners();
     this.getLandingDesignImages(); 
   }
   getLandingDesignImages() {
     this.apiService.getLandingDesignImages().subscribe((resp:any) => {
       // this.designImagesList = resp;
     });
   }
   get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }
   showInterCom() {
     (<any>window).Intercom(
       "showNewMessage",
       "Hi there! I see you would like to chat with a design consultant."
     );
   }
   getDesigners() {
     this.apiService.getDesignProfile().subscribe((res: any) => {
       this.profileList = res;
     });
   } 
   
   get rightHeight():number{
     console.log(document.getElementById('rightHeight'))
     return (document.getElementById('rightHeight').clientHeight) - 100;
   }
 }
 